.tabs {
  margin-bottom: 20px;

  .tab-list {
    display: none;
    margin: 0 0 10px;
    list-style: none;
    text-transform: none;
    font-weight: 700;

    &[role='tablist'] {
      display: inline-flex;
      align-items: flex-end;
      margin-bottom: 0;    
    }

    li {
      display: inline-block;
      margin: 0 5px 0 0;
      padding: 0;
      white-space: nowrap;

      a {
        display: block;
        padding: 10px 15px;
        color: white;
        // border-bottom: 1px solid gray;
        font-size: 16px;
        line-height: 18px;

        &:hover
        &:focus {
          padding: 10px 15px;
          // border-bottom: 1px solid black;
        }

        &[role='tab']:focus {
          color: white;
          outline: 0 !important;
          // border-bottom: 1px solid black;
        }

        &[aria-selected] {
          color: white;
          background: black;
          // padding: 10px 15px;
          // border-bottom: 1px solid black;

          &:hover,
          &:focus,
          &[role='tab']:focus {
            color: white;
            background: black;
            outline: 0 !important;
            // border-bottom: 1px solid gray;
          }
        }
      }
    }
  }

  .tab-con {
    margin-bottom: 0;
  }

  [role='tabpanel'] {
    padding: 20px;
    font-size: 15px;
    line-height: 18px;
    border: 1px solid gray;
    // background-color: #e6e6e6;

    &[aria-hidden='true'] {
      display: none;
    }

    .tab-title {
      display: none;
    }
  }
}

// sample of overflow code for horizontal scrolling tabs on mobile
// .tabs {
//   position: relative;
//   margin-bottom: rem-calc(20px);
//   &:not(.location-tabs).scroll-left:before,
//   &:not(.location-tabs).scroll-right:after {
//     position: absolute;
//     z-index: 99;
//     width: rem-calc(40px);
//     height: rem-calc(60px);
//     content: '';
//   }
//   &:not(.location-tabs).scroll-left:before {
//     top: 0;
//     left: 0;
//     background: linear-gradient(to right, rgba(white, 1) 0%, rgba(white, 0) 100%);
//   }
//   &:not(.location-tabs).scroll-right:after {
//     top: 0;
//     right: 0;
//     background: linear-gradient(to left, rgba(white, 1) 0%, rgba(white, 0) 100%);
//   }
//   .tab-con {
//     position: relative;
//     overflow-x: auto;
//     overflow-y: hidden;
//     -webkit-overflow-scrolling: touch;
//     margin-bottom: rem-calc(24px);
//     padding: rem-calc(0 0 10px 0);
//     white-space: nowrap;
//     @include large-up {
//       margin-bottom: rem-calc(35px);
//       @include fontsize(18 15);
//     }
//   }
//   .tab-list {
//     display: none;
//     margin: 0;
//     padding: 0;
//     list-style: none;
//     text-align: center;
//     text-transform: uppercase;
//     letter-spacing: 1px;
//     font-weight: 900;
//     font-family: $myriad-con;
//     @include medium-up {
//       text-align: left;
//     }
//     @include large-up {
//       @include fontsize(18 15);
//     }
//     @include fontsize(15 15);
//     &[role='tablist'] {
//       display: inline-block;
//     }
//     li {
//       display: inline-block;
//       margin: rem-calc(0 5px 0 0);
//       padding: 0;
//       white-space: nowrap;
//       &:last-child {
//         margin: 0;
//       }
//       a {
//         position: relative;
//         display: block;
//         padding: rem-calc(8px 19px);
//         border: 2px solid $tabs-inactive-border;
//         border-radius: rem-calc(21px);
//         background: $tabs-inactive-bg;
//         color: $tabs-color;
//         text-align: center;
//         @include large-up {
//           padding: rem-calc(10px 40px);
//         }
//         &:hover,
//         &[role='tab']:focus {
//           outline: 0;
//           border-color: $tabs-inactive-border-hover;
//           background: $tabs-inactive-bg-hover;
//           color: $tabs-color;
//         }
//         &[aria-selected] {
//           padding: rem-calc(10px 20px);
//           border: 0;
//           background: $tabs-active;
//           color: white;
//           text-shadow: -1px -1px 2px rgba(0, 0, 0, 0.15);
//           @include large-up {
//             padding: rem-calc(12px 41px);
//           }
//           &:after {
//             position: absolute;
//             top: 100%;
//             right: 0;
//             left: 0;
//             margin: 0 auto;
//             width: 0;
//             height: 0;
//             border-width: rem-calc(7px 7.5px 0 7.5px);
//             border-style: solid;
//             border-color: $tabs-active transparent transparent transparent;
//             content: '';
//             @include large-up {
//               border-width: rem-calc(10px 10px 0 10px);
//             }
//           }
//           &:hover,
//           &[role='tab']:focus {
//             border-color: $tabs-active;
//             background: $tabs-active;
//             color: white;
//             text-shadow: -1px -1px 2px rgba(0, 0, 0, 0.15);
//           }
//         }
//       }
//     }
//   }
//   .tab-title {
//     margin: rem-calc(0 0 20px 0);
//     color: $tabs-color;
//     text-transform: uppercase;
//     letter-spacing: 0.75px;
//     font-weight: 900;
//     font-family: $myriad-con;
//   }
//   .tab-content {
//     margin-bottom: rem-calc(24px);
//     &:last-child {
//       margin-bottom: 0;
//     }
//   }
//   [role='tabpanel'] {
//     @include fontsize(15 18);
//     &[aria-hidden='true'] {
//       display: none;
//     }
//     .tab-title {
//       display: none;
//     }
//   }
// }