.parallax-container {
  background-position: center center !important;
}

#page-title {
  border: none;
  background: url(/themes/Conns/assets/dist/images/Conns-About-background.png);
}

.background-overlay,
.background-overlay-dark,
.background-overlay-light {
  img {
    position: absolute;
    top: 0;
    z-index: -1;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    opacity: 1;
  }

  .banner-logo img {
    position: relative !important;
    z-index: 0;
    margin: auto;
    padding: 25px;
    min-width: auto;
    max-width: 100%;
    max-height: 400px;
    opacity: 1 !important;
  }
}

.background-overlay-dark {
  &:before {
    background: rgba(0, 0, 0, 0.5);
  }
}

.inspiro-slider .slide-captions h1 {
  background: transparent;
}
