/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/
.sr-only {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  color: black;
  background: white;
}

.offscreen {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.hide {
  display: none;
}

.show {
  display: initial;
}

.invisible {
  visibility: hidden;
}

.clear {
  display: block;
  clear: both;
}

.clear-after:after {
  display: table;
  clear: both;
  content: "";
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.float-left {
  float: left;
  padding: 0 20px 10px 0;
}

.float-right {
  float: right;
  padding: 0 0 10px 20px;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media (orientation: landscape) {
  .show-landscape {
    display: block;
  }
}
@media (orientation: portrait) {
  .show-landscape {
    display: none;
  }
}

@media (orientation: portrait) {
  .show-portrait {
    display: block;
  }
}
@media (orientation: landscape) {
  .show-portrait {
    display: none;
  }
}

@media (orientation: landscape) {
  .hide-landscape {
    display: none;
  }
}
@media (orientation: portrait) {
  .hide-landscape {
    display: block;
  }
}

@media (orientation: portrait) {
  .hide-portrait {
    display: none;
  }
}
@media (orientation: landscape) {
  .hide-portrait {
    display: block;
  }
}

@media (min-width: 640px) {
  .show-small-only {
    display: none;
  }
}

@media (min-width: 0) and (max-width: 639px) {
  .show-medium-only {
    display: none;
  }
}
@media (min-width: 1024px) {
  .show-medium-only {
    display: none;
  }
}

.show-large-only {
  display: none;
}
@media (min-width: 1024px) and (max-width: 1340px) {
  .show-large-only {
    display: block;
  }
}
@media (min-width: 1341px) {
  .show-large-only {
    display: none;
  }
}

.show-xlarge-only {
  display: none;
}
@media (min-width: 1341px) and (max-width: 1399px) {
  .show-xlarge-only {
    display: block;
  }
}
@media (min-width: 1400px) {
  .show-xlarge-only {
    display: none;
  }
}

.show-xxlarge-only {
  display: none;
}
@media (min-width: 1400px) {
  .show-xxlarge-only {
    display: none;
  }
}

.show-medium-up {
  display: none;
}
@media (min-width: 640px) {
  .show-medium-up {
    display: block;
  }
}

.show-large-up {
  display: none;
}
@media (min-width: 1024px) {
  .show-large-up {
    display: block;
  }
}

.show-xlarge-up {
  display: none;
}
@media (min-width: 1341px) {
  .show-xlarge-up {
    display: block;
  }
}

.show-xxlarge-up {
  display: none;
}
@media (min-width: 1400px) {
  .show-xxlarge-up {
    display: block;
  }
}

@media (min-width: 0) and (max-width: 639px) {
  .hide-small-only {
    display: none;
  }
}

@media (min-width: 640px) and (max-width: 1023px) {
  .hide-medium-only {
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 1340px) {
  .hide-large-only {
    display: none;
  }
}

@media (min-width: 1341px) and (max-width: 1399px) {
  .hide-xlarge-only {
    display: none;
  }
}

@media (min-width: 1400px) {
  .hide-xxlarge-only {
    display: none;
  }
}

@media (min-width: 640px) {
  .hide-medium-up {
    display: none;
  }
}

@media (min-width: 1024px) {
  .hide-large-up {
    display: none;
  }
}

@media (min-width: 1341px) {
  .hide-xlarge-up {
    display: none;
  }
}

@media (min-width: 1400px) {
  .hide-xxlarge-up {
    display: none;
  }
}

.sidebar .call-to-action {
  padding: 10px;
}

.content-form {
  position: relative;
  overflow: hidden;
  margin-right: -1.6875rem;
  margin-left: -1.6875rem;
  /* recaptcha */
  /* small screens */
  /* medium screens */
  /* large screens */
}
.content-form h2,
.content-form .form-header,
.content-form .form-footer {
  padding-right: 1.6875rem;
  padding-left: 1.6875rem;
}
.content-form h2 p:empty,
.content-form .form-header p:empty,
.content-form .form-footer p:empty {
  margin: 0;
}
.content-form .form-footer {
  margin-top: 1.875rem;
}
.content-form .validation-summary {
  display: none;
}
.content-form fieldset {
  border: 0;
  margin: 0 0 1.875rem 0;
  padding: 0;
}
.content-form fieldset:last-of-type {
  margin-bottom: 0;
}
.content-form label {
  position: relative;
  display: inline-block;
  clear: both;
}
.content-form input[type=text],
.content-form input[type=password],
.content-form input[type=date],
.content-form input[type=datetime],
.content-form input[type=datetime-local],
.content-form input[type=month],
.content-form input[type=week],
.content-form input[type=email],
.content-form input[type=number],
.content-form input[type=search],
.content-form input[type=tel],
.content-form input[type=time],
.content-form input[type=url],
.content-form textarea,
.content-form select {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 0 1.875rem 0;
  appearance: none;
}
.content-form input[type=text]:active, .content-form input[type=text]:focus,
.content-form input[type=password]:active,
.content-form input[type=password]:focus,
.content-form input[type=date]:active,
.content-form input[type=date]:focus,
.content-form input[type=datetime]:active,
.content-form input[type=datetime]:focus,
.content-form input[type=datetime-local]:active,
.content-form input[type=datetime-local]:focus,
.content-form input[type=month]:active,
.content-form input[type=month]:focus,
.content-form input[type=week]:active,
.content-form input[type=week]:focus,
.content-form input[type=email]:active,
.content-form input[type=email]:focus,
.content-form input[type=number]:active,
.content-form input[type=number]:focus,
.content-form input[type=search]:active,
.content-form input[type=search]:focus,
.content-form input[type=tel]:active,
.content-form input[type=tel]:focus,
.content-form input[type=time]:active,
.content-form input[type=time]:focus,
.content-form input[type=url]:active,
.content-form input[type=url]:focus,
.content-form textarea:active,
.content-form textarea:focus,
.content-form select:active,
.content-form select:focus {
  outline: 0;
}
.content-form textarea {
  height: auto;
  min-height: 7.5rem;
}
.content-form .error-msg {
  position: relative;
  top: -1.875rem;
  max-width: 100%;
  margin-top: 0;
  color: red;
}
.content-form .required label:after {
  position: relative;
  top: -7px;
  margin-left: 2px;
  content: "\f069";
  text-decoration: inherit;
  font-family: FontAwesome;
  font-size: 0.375rem;
  font-weight: normal;
  font-style: normal;
}
.content-form .checkbox {
  overflow: hidden;
}
.content-form input[type=submit] {
  margin-left: 1.6875rem;
  border: 0;
}
.content-form .g-recaptcha {
  padding-right: 1.6875rem;
  padding-left: 1.6875rem;
  margin: 0 0 1.875rem 0;
}
.content-form .sm-full {
  float: left;
  clear: none;
  width: 100%;
  padding-right: 1.6875rem;
  padding-left: 1.6875rem;
}
.content-form .sm-half {
  float: left;
  clear: none;
  width: 50%;
  padding-right: 1.6875rem;
  padding-left: 1.6875rem;
}
.content-form .sm-third {
  float: left;
  clear: none;
  width: 33.333%;
  padding-right: 1.6875rem;
  padding-left: 1.6875rem;
}
.content-form .sm-two-thirds {
  float: left;
  clear: none;
  width: 66.666%;
  padding-right: 1.6875rem;
  padding-left: 1.6875rem;
}
.content-form .sm-quarter {
  float: left;
  clear: none;
  width: 25%;
  padding-right: 1.6875rem;
  padding-left: 1.6875rem;
}
.content-form .sm-three-quarters {
  float: left;
  clear: none;
  width: 75%;
  padding-right: 1.6875rem;
  padding-left: 1.6875rem;
}
.content-form .sm-last + div {
  clear: both;
}
@media screen and (min-width: 40rem) {
  .content-form .sm-last + div {
    clear: none;
  }
  .content-form .md-full {
    float: left;
    clear: none;
    width: 100%;
    padding-right: 1.6875rem;
    padding-left: 1.6875rem;
  }
  .content-form .md-half {
    float: left;
    clear: none;
    width: 50%;
    padding-right: 1.6875rem;
    padding-left: 1.6875rem;
  }
  .content-form .md-third {
    float: left;
    clear: none;
    width: 33.333%;
    padding-right: 1.6875rem;
    padding-left: 1.6875rem;
  }
  .content-form .md-two-thirds {
    float: left;
    clear: none;
    width: 66.666%;
    padding-right: 1.6875rem;
    padding-left: 1.6875rem;
  }
  .content-form .md-quarter {
    float: left;
    clear: none;
    width: 25%;
    padding-right: 1.6875rem;
    padding-left: 1.6875rem;
  }
  .content-form .md-three-quarters {
    float: left;
    clear: none;
    width: 75%;
    padding-right: 1.6875rem;
    padding-left: 1.6875rem;
  }
  .content-form .md-last + div {
    clear: both;
  }
}
@media screen and (min-width: 64rem) {
  .content-form .sm-last + div {
    clear: none;
  }
  .content-form .md-last + div {
    clear: none;
  }
  .content-form .lg-full {
    float: left;
    clear: none;
    width: 100%;
    padding-right: 1.6875rem;
    padding-left: 1.6875rem;
  }
  .content-form .lg-half {
    float: left;
    clear: none;
    width: 50%;
    padding-right: 1.6875rem;
    padding-left: 1.6875rem;
  }
  .content-form .lg-third {
    float: left;
    clear: none;
    width: 33.333%;
    padding-right: 1.6875rem;
    padding-left: 1.6875rem;
  }
  .content-form .lg-two-thirds {
    float: left;
    clear: none;
    width: 66.666%;
    padding-right: 1.6875rem;
    padding-left: 1.6875rem;
  }
  .content-form .lg-quarter {
    float: left;
    clear: none;
    width: 25%;
    padding-right: 1.6875rem;
    padding-left: 1.6875rem;
  }
  .content-form .lg-three-quarters {
    clear: none;
    width: 75%;
    padding-right: 1.6875rem;
    padding-left: 1.6875rem;
  }
  .content-form .lg-last + div {
    clear: both;
  }
}

.accordions {
  margin-bottom: 20px;
}
.accordions .accordion-title {
  position: relative;
}
.accordions .accordion-title button {
  position: relative;
  width: 100%;
  margin: 0 0 0.625rem 0;
  padding: 1.25rem 5.125rem 0.625rem 0;
  text-align: left;
  color: black;
  border: 0;
  border-bottom: 2px solid gray;
  background: transparent;
  font-weight: 700;
  font-size: 0.9375rem;
  line-height: 1.125rem;
  letter-spacing: normal;
}
.accordions .accordion-title button span {
  position: absolute;
  right: 0;
  bottom: -2px;
  width: 5rem;
  height: calc(100% + 2px);
  border-bottom: 2px solid gray;
}
.accordions .accordion-title button span:after {
  position: absolute;
  top: 50%;
  left: 2rem;
  content: "\f067";
  transform: translateY(-8px);
  text-align: center;
  text-decoration: inherit;
  color: black;
  font-family: FontAwesome;
  font-size: 1.125rem;
  font-weight: normal;
  font-style: normal;
}
.accordions .accordion-title button:hover {
  cursor: pointer;
}
.accordions .accordion-title button:hover, .accordions .accordion-title button:focus {
  color: blue;
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
  background: transparent;
}
.accordions .accordion-title button:hover span, .accordions .accordion-title button:focus span {
  border-bottom: 2px solid gray;
}
.accordions .accordion-title.is-active button, .accordions .accordion-title.is-active button:focus {
  color: blue;
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
  background: transparent;
}
.accordions .accordion-title.is-active button span, .accordions .accordion-title.is-active button:focus span {
  border-bottom: 2px solid gray;
}
.accordions .accordion-title.is-active button span:after, .accordions .accordion-title.is-active button:focus span:after {
  content: "\f068";
}
.accordions .accordion-content[aria-hidden] {
  display: none;
}
.accordions .accordion-content[aria-hidden=false] {
  display: block;
}
.accordions.active h2 {
  margin: 0;
}
.tabs {
  position: relative;
  margin-bottom: 1.25rem;
}
.tabs:not(.location-tabs).scroll-left:before, .tabs:not(.location-tabs).scroll-right:after {
  position: absolute;
  z-index: 99;
  width: 2.5rem;
  height: 3.75rem;
  content: "";
}
.tabs:not(.location-tabs).scroll-left:before {
  top: 0;
  left: 0;
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
}
.tabs:not(.location-tabs).scroll-right:after {
  top: 0;
  right: 0;
  background: linear-gradient(to left, white 0%, rgba(255, 255, 255, 0) 100%);
}
.tabs .tab-con {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 2px;
  padding: 0;
  white-space: nowrap;
}
.tabs .tab-list {
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 900;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  letter-spacing: normal;
}
.tabs .tab-list[role=tablist] {
  display: inline-block;
}
.tabs .tab-list li {
  display: inline-block;
  margin: 0 3px 0 0;
  padding: 0;
  white-space: nowrap;
}
.tabs .tab-list li:last-child {
  margin: 0;
}
.tabs .tab-list li a {
  position: relative;
  display: block;
  padding: 0.75rem 1.1875rem;
  background: gray;
  color: black;
  text-align: center;
  text-decoration: none;
}
.tabs .tab-list li a:hover, .tabs .tab-list li a[role=tab]:focus {
  outline: 0;
  background: black;
  color: white;
}
.tabs .tab-list li a[aria-selected] {
  border: 0;
  background: gray;
  color: black;
  padding: 0.75rem 1.1875rem;
}
.tabs .tab-list li a[aria-selected]:hover, .tabs .tab-list li a[aria-selected][role=tab]:focus {
  background: gray;
  color: black;
}
.tabs [role=tabpanel][aria-hidden=true] {
  display: none;
}
.tabs [role=tabpanel] .tab-title {
  display: none;
}

table.tablesaw {
  width: 100%;
  max-width: 100%;
  margin: 0 0 1.5rem 0;
  padding: 0;
  empty-cells: show;
  border: 0;
  border-collapse: collapse;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  letter-spacing: normal;
}

.tablesaw * {
  box-sizing: border-box;
}

.tablesaw-stack td .tablesaw-cell-label,
.tablesaw-stack th .tablesaw-cell-label {
  display: none;
}

@media only all {
  .tablesaw-stack {
    clear: both;
  }
  .tablesaw-stack td,
.tablesaw-stack th {
    display: block;
    text-align: left;
  }
  .tablesaw-stack tr {
    display: table-row;
    clear: both;
  }
  .tablesaw-stack td .tablesaw-cell-label,
.tablesaw-stack th .tablesaw-cell-label {
    display: inline-block;
    width: 30%;
    padding: 0 10px 0 0;
  }
  .tablesaw-stack th .tablesaw-cell-label-top,
.tablesaw-stack td .tablesaw-cell-label-top {
    display: block;
    margin: 6px 0;
    padding: 6px 0;
  }
  .tablesaw-stack .tablesaw-cell-label {
    display: block;
  }
  .tablesaw-stack tbody th.group {
    margin-top: -1px;
  }
  .tablesaw-stack th.group b.tablesaw-cell-label {
    display: none !important;
  }
}
@media (max-width: 39.9375em) {
  table.tablesaw-stack {
    margin: 0 -1.25rem 1.5rem -1.25rem;
    width: calc(100% + 40px);
    max-width: calc(100% + 40px);
  }
  table.tablesaw-stack tbody tr {
    display: block;
    width: 100%;
    padding: 0 1.25rem;
  }
  table.tablesaw-stack tbody tr:last-child {
    border-bottom: 1px solid gray;
  }
  table.tablesaw-stack thead {
    display: none;
  }
  table.tablesaw-stack tbody td,
table.tablesaw-stack tbody th {
    display: block;
    clear: left;
    width: 100%;
    padding: 0;
  }
  table.tablesaw-stack tbody td:last-child,
table.tablesaw-stack tbody th:last-child {
    border-bottom: 0;
  }
  table.tablesaw-stack td .tablesaw-cell-label {
    display: inline-block;
    padding: 0.5rem 0.5rem 0.5rem 0;
    text-transform: uppercase;
    vertical-align: top;
    background: transparent;
    font-weight: 700;
  }
  table.tablesaw-stack td .tablesaw-cell-content {
    display: inline-block;
    max-width: 67%;
    padding: 0.5rem 0 0.5rem 0.5rem;
  }
  table.tablesaw-stack .tablesaw-stack-block .tablesaw-cell-label,
table.tablesaw-stack .tablesaw-stack-block .tablesaw-cell-content {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
  table.tablesaw-stack td:empty,
table.tablesaw-stack th:empty {
    display: none;
  }
}
@media (min-width: 40em) {
  .tablesaw-stack tr {
    display: table-row;
  }
  .tablesaw-stack td,
.tablesaw-stack th,
.tablesaw-stack thead td,
.tablesaw-stack thead th {
    display: table-cell;
    margin: 0;
  }
  .tablesaw-stack td .tablesaw-cell-label,
.tablesaw-stack th .tablesaw-cell-label {
    display: none !important;
  }
  .tablesaw-stack thead th {
    padding: 0.625rem 1.125rem 0.5rem;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
  }
  .tablesaw-stack thead th:last-child {
    border-right: 0;
  }
  .tablesaw-stack tbody tr td {
    padding: 0.75rem 1.125rem 0.625rem;
  }
  .tablesaw-stack tbody tr td:last-child {
    border-right: 0;
  }
}
.flex-container {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 1024px) {
  .flex-container.alignleft {
    width: 40%;
    float: left;
    margin: 0 15px 10px 0;
  }
  .flex-container.alignright {
    width: 40%;
    float: right;
    margin: 0 0 10px 15px;
  }
  .flex-container.aligncenter {
    width: 40%;
    margin: 0 auto 10px auto;
  }
}

.flex-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}
.flex-video iframe,
.flex-video object,
.flex-video embed,
.flex-video video {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.search-results {
  font-size: 0.875rem;
  line-height: 1.375rem;
  letter-spacing: normal;
}
.search-results .stats {
  margin: 1.875rem 0;
}
.search-results .search-results-search {
  background: lightgray;
  overflow: hidden;
  padding: 0.625rem;
  margin-bottom: 1.875rem;
}
.search-results .search-results-search input[name=search] {
  border: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  height: 1.875rem;
  padding: 0 8px;
  width: calc(100% - 118px);
  font-style: italic;
  float: left;
}
.search-results .search-results-search input[name=search]::-webkit-input-placeholder {
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  font-style: italic;
}
.search-results .search-results-search input[name=search]:-moz-placeholder {
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  font-style: italic;
}
.search-results .search-results-search input[name=search]::-moz-placeholder {
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  font-style: italic;
}
.search-results .search-results-search input[name=search]:-ms-input-placeholder {
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  font-style: italic;
}
.search-results .search-results-search button[name=search-submit] {
  display: inline-block;
  border: 0;
  padding: 0 8px;
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  height: 1.875rem;
  width: 7.125rem;
  text-align: center;
  float: right;
  text-decoration: none;
}
.search-results .search-results-search button[name=search-submit]:hover, .search-results .search-results-search button[name=search-submit]:focus {
  text-decoration: none;
}
.search-results .results {
  position: relative;
  padding-top: 1.25rem;
}
.search-results .results:before {
  content: "";
  background: gray;
  position: absolute;
  top: -1rem;
  width: 100%;
  height: 1px;
}
.search-results .pagination {
  border-top: 1px solid gray;
}
.search-results .pagination > td {
  padding: 1rem 0 0 0;
}
.search-results .pagination span,
.search-results .pagination a {
  background: lightgray;
  color: gray;
  display: inline-block;
  text-decoration: none;
  padding: 3px 6px;
  border: 1px solid gray;
  font-size: 0.8125rem;
  line-height: 0.8125rem;
  letter-spacing: normal;
}
.search-results .pagination a {
  color: blue;
}
.search-results .pagination a:visited {
  color: blue;
}
.search-results .pagination a:hover, .search-results .pagination a:focus {
  color: red;
}
.search-results th[scope=col] {
  display: none;
}
.search-results tr:nth-child(n+2):not(.pagination) > td {
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
  padding-bottom: 1.25rem;
}
.search-results tr:nth-child(n+2):not(.pagination) > td a {
  display: block;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  letter-spacing: normal;
  letter-spacing: 0.0625em;
  font-weight: 400;
}

.two-column,
.three-column,
.four-column,
.five-column,
.six-column {
  list-style: none;
  margin: 0;
  padding: 0;
}
@media (min-width: 640px) {
  .two-column,
.three-column,
.four-column,
.five-column,
.six-column {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.9375rem;
  }
}
.two-column .col,
.three-column .col,
.four-column .col,
.five-column .col,
.six-column .col {
  width: 100%;
}
.two-column li,
.three-column li,
.four-column li,
.five-column li,
.six-column li {
  margin: 0;
  padding: 0;
}
.two-column li:before,
.three-column li:before,
.four-column li:before,
.five-column li:before,
.six-column li:before {
  display: none;
}

@media (min-width: 640px) {
  .two-column .col {
    width: 50%;
    padding: 0 0.9375rem 0.9375rem 0.9375rem;
  }
}

@media (min-width: 640px) {
  .three-column .col {
    width: 50%;
    padding: 0 0.9375rem 0.9375rem 0.9375rem;
  }
}
@media (min-width: 1024px) {
  .three-column .col {
    width: 33.3%;
  }
}

@media (min-width: 640px) {
  .four-column .col {
    width: 50%;
    padding: 0 0.9375rem 0.9375rem 0.9375rem;
  }
}
@media (min-width: 1024px) {
  .four-column .col {
    width: 25%;
  }
}

@media (min-width: 640px) {
  .five-column .col {
    width: 50%;
    padding: 0 0.9375rem 0.9375rem 0.9375rem;
  }
}
@media (min-width: 1024px) {
  .five-column .col {
    width: 20%;
  }
}

@media (min-width: 640px) {
  .six-column .col {
    width: 50%;
    padding: 0 0.9375rem 0.9375rem 0.9375rem;
  }
}
@media (min-width: 1024px) {
  .six-column .col {
    width: 16.6666666667%;
  }
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}

.accordions {
  margin-bottom: 20px;
}
.accordions .accordion-title {
  position: relative;
  min-height: 45px;
  margin: 20px 0 0;
  padding: 15px 45px 15px 0;
  color: gray;
  font-size: 18px;
  line-height: 17px;
}
.accordions .accordion-title:hover {
  cursor: pointer;
  color: black;
}
.accordions .accordion-title:first-of-type {
  margin-top: 0;
}
.accordions .accordion-title.is-active {
  margin-bottom: 10px;
}
.accordions .accordion-title.is-active button {
  color: black;
  font-weight: 700;
}
.accordions .accordion-title.is-active button:focus {
  color: black;
  top: 1px;
  border-bottom: 2px solid black;
  font-weight: 700;
}
.accordions .accordion-title.is-active button::after {
  transform: scaleY(-1);
}
.accordions .accordion-title button {
  position: absolute;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  text-align: left;
  color: black;
  font-size: 18px;
  line-height: 17px;
}
.accordions .accordion-title button:hover, .accordions .accordion-title button:focus {
  color: black;
  border-bottom: 2px solid black;
  outline: 0;
}
.accordions .accordion-title button::after {
  content: "\f078";
  position: absolute;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  right: 0;
  color: #999;
  padding: 0 13px;
  margin-left: 15px;
  transition: 0.5s ease-in-out;
}
.accordions .accordion-title + [aria-hidden] {
  display: none;
}
.accordions .accordion-title + [aria-hidden=false] {
  display: block;
}
.accordions .accordion-content {
  margin-top: 10px;
  padding: 20px;
  color: black;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
}

.tabs {
  margin-bottom: 20px;
}
.tabs .tab-list {
  display: none;
  margin: 0 0 10px;
  list-style: none;
  text-transform: none;
  font-weight: 700;
}
.tabs .tab-list[role=tablist] {
  display: inline-flex;
  align-items: flex-end;
  margin-bottom: 0;
}
.tabs .tab-list li {
  display: inline-block;
  margin: 0 5px 0 0;
  padding: 0;
  white-space: nowrap;
}
.tabs .tab-list li a {
  display: block;
  padding: 10px 15px;
  color: white;
  font-size: 16px;
  line-height: 18px;
}
.tabs .tab-list li a:hover .tabs .tab-list li a:focus {
  padding: 10px 15px;
}
.tabs .tab-list li a[role=tab]:focus {
  color: white;
  outline: 0 !important;
}
.tabs .tab-list li a[aria-selected] {
  color: white;
  background: black;
}
.tabs .tab-list li a[aria-selected]:hover, .tabs .tab-list li a[aria-selected]:focus, .tabs .tab-list li a[aria-selected][role=tab]:focus {
  color: white;
  background: black;
  outline: 0 !important;
}
.tabs .tab-con {
  margin-bottom: 0;
}
.tabs [role=tabpanel] {
  padding: 20px;
  font-size: 15px;
  line-height: 18px;
  border: 1px solid gray;
}
.tabs [role=tabpanel][aria-hidden=true] {
  display: none;
}
.tabs [role=tabpanel] .tab-title {
  display: none;
}

body.obeds #side-panel-trigger {
  color: #e9c555 !important;
}
body.obeds #side-panel {
  background-color: #15264a;
}
body.obeds #side-panel nav.primary-nav .ul1 .li1 .a1 {
  color: white !important;
}
@media only screen and (max-width: 991px) {
  body.obeds #portfolio.location-boxes {
    height: auto !important;
  }
  body.obeds #portfolio.location-boxes .portfolio-item {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    display: block;
    width: 100% !important;
    transition: none !important;
    transform: none !important;
  }
  body.obeds #portfolio.location-boxes .portfolio-item .portfolio-description a {
    text-decoration: none;
  }
  body.obeds #portfolio.location-boxes .portfolio-item .portfolio-description span {
    display: block !important;
  }
  body.obeds #portfolio.location-boxes .portfolio-item .portfolio-description h2,
body.obeds #portfolio.location-boxes .portfolio-item .portfolio-description h3 {
    padding: 20px 0 8px !important;
  }
}
body.obeds #portfolio.location-boxes .portfolio-item:nth-of-type(odd) h2,
body.obeds #portfolio.location-boxes .portfolio-item:nth-of-type(odd) h3 {
  background: #e9c555 !important;
  color: #15264a !important;
}
body.obeds #portfolio.location-boxes .portfolio-item:nth-of-type(odd) span {
  color: #15264a !important;
}
body.obeds #portfolio.location-boxes .portfolio-item:hover h2,
body.obeds #portfolio.location-boxes .portfolio-item:hover h3 {
  background: #15264a !important;
  color: white !important;
}
body.obeds #portfolio.location-boxes .portfolio-item:hover span {
  color: white !important;
}
body.obeds #portfolio.location-boxes .portfolio-item .portfolio-description {
  position: absolute;
  top: 0 !important;
  bottom: 0 !important;
  visibility: visible;
  padding: 0 !important;
  width: 100% !important;
  height: auto !important;
  text-align: center;
  opacity: 1;
  transform: none;
}
body.obeds #portfolio.location-boxes .portfolio-item .portfolio-description:hover span {
  text-decoration: none;
}
body.obeds #portfolio.location-boxes .portfolio-item .portfolio-description span {
  position: absolute;
  bottom: 7px;
  z-index: 1;
  display: none;
  margin: 0;
  padding: 0.875rem;
  width: 100%;
  color: #15264a;
  text-transform: uppercase;
  font-size: 10px;
  font-family: "open sans";
  opacity: 1;
  transition: none;
}
body.obeds #portfolio.location-boxes .portfolio-item .portfolio-description h2,
body.obeds #portfolio.location-boxes .portfolio-item .portfolio-description h3 {
  position: absolute;
  bottom: 0;
  margin: 0;
  padding: 14px;
  width: 100%;
  background: #dfb427;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  line-height: 18px;
}
body.obeds ::selection {
  background: #e9c555;
}
body.obeds a {
  color: #15264a;
  font-weight: 400;
}
body.obeds .background-overlay,
body.obeds .background-overlay-dark,
body.obeds .background-overlay-light {
  background: url("/themes/Conns/assets/dist/images/obed-and-isaacs-subpage-banner.png");
  background-position: center center;
  background-size: cover;
}
body.obeds .page-menu.page-menu-active nav {
  background-color: #15264a !important;
}
body.obeds .page-menu.page-menu-active nav ul li a {
  color: white;
}
body.obeds .subnav-container {
  background: #e9c555;
}
body.obeds .subnav-container .page-title {
  background: #15264a;
  color: white;
}
@media only screen and (max-width: 991px) {
  body.obeds .subnav-container .page-title {
    background: #e9c555;
    color: #15264a;
  }
}
body.obeds .subnav-container #menu-responsive-icon {
  background-color: #e9c555 !important;
  color: white;
}
body.obeds .subnav-container #menu-responsive-icon:before {
  background-color: #15264a;
}
body.obeds .subnav-container nav ul li:hover a, body.obeds .subnav-container nav ul li:active a {
  color: white;
}
body.obeds .subnav-container nav ul li:hover a:after, body.obeds .subnav-container nav ul li:active a:after {
  background-color: white;
}
body.obeds .subnav-container nav ul li a.active {
  background-color: #15264a !important;
  color: white;
}
body.obeds .ball-beat > div,
body.obeds .ball-grid-beat > div,
body.obeds .ball-grid-pulse > div,
body.obeds .ball-pulse-sync > div,
body.obeds .ball-rotate > div,
body.obeds .ball-rotate > div:after,
body.obeds .ball-rotate > div:before,
body.obeds .ball-scale-multiple > div,
body.obeds .ball-scale-ripple > div,
body.obeds .ball-spin-fade-loader > div,
body.obeds .ball-triangle-path > div,
body.obeds .ball-zig-zag > div,
body.obeds .cube-transition > div,
body.obeds .line-scale-pulse-out-rapid > div,
body.obeds .line-scale > div,
body.obeds .square-spin > div {
  background: #e9c555;
}
body.obeds .obed.call-outs .call-out {
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
body.obeds .obed.call-outs .call-out:nth-child(1n) {
  background: url("/themes/Conns/assets/dist/images/img-obeds-storyOfObeds.jpg");
}
body.obeds .obed.call-outs .call-out:nth-child(2n) {
  background: url("/themes/Conns/assets/dist/images/img-obeds-photoGallery.jpg");
}
body.obeds .obed.call-outs .call-out:nth-child(3n) {
  background: url("/themes/Conns/assets/dist/images/img-obeds-contactUs.jpg");
}
body.obeds .socials {
  position: absolute;
  top: 25%;
  right: -8%;
}
body.obeds .socials a {
  color: #15264a !important;
}
body.obeds .socials a:hover {
  color: #e9c555 !important;
}
body.obeds .location-picker .location {
  display: inline-flex;
  margin: 20px;
}
@media only screen and (max-width: 480px) {
  body.obeds .location-picker .location {
    margin: 20px 10px;
  }
}
body.obeds .location-picker .location a:hover .springfield {
  background: url(/themes/Conns/assets/dist/images/btn-obeds-Springfield-on.png) no-repeat;
}
body.obeds .location-picker .location a:hover .peoria {
  background: url(/themes/Conns/assets/dist/images/btn-obeds-peoria-on.png) no-repeat;
}
body.obeds .location-picker .location .springfield {
  width: 120px;
  height: 120px;
  background: url(/themes/Conns/assets/dist/images/btn-obeds-Springfield.png) no-repeat;
}
body.obeds .location-picker .location .springfield.active {
  background: url(/themes/Conns/assets/dist/images/btn-obeds-Springfield-on.png) no-repeat;
}
body.obeds .location-picker .location .peoria {
  width: 120px;
  height: 120px;
  background: url(/themes/Conns/assets/dist/images/btn-obeds-peoria.png) no-repeat;
}
body.obeds .location-picker .location .peoria.active {
  background: url(/themes/Conns/assets/dist/images/btn-obeds-peoria-on.png) no-repeat;
}
body.obeds .location-picker .location span {
  position: relative;
  display: block;
  margin: 10px 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px !important;
  font-family: "Open Sans";
  line-height: 12px;
}
body.obeds .location-info .widget span {
  color: #15264a;
}
body.obeds .location-info .widget a {
  color: #15264a;
  font-size: 16px;
}
body.obeds footer.footer-light {
  border-top: 1px solid #a08055 !important;
  background-color: #e9c555 !important;
}
body.obeds footer.footer-light .footer-content {
  color: #15264a !important;
}
body.obeds footer.footer-light .footer-content .widget span {
  color: #353e4c !important;
}
body.obeds footer.footer-light .footer-content .widget ul.list-icon li span:before {
  color: #15264a !important;
}
body.obeds footer.footer-light .footer-content .widget ul.list-icon li strong {
  color: #15264a !important;
}
body.obeds footer.footer-light .footer-content a {
  color: #15264a !important;
}
body.obeds .background-overlay .parallax-container {
  background-position: center center !important;
}
body.obeds input[type=submit] {
  background-color: #15264a;
}
body.obeds input[type=submit]:hover {
  background-color: #e9c555;
  color: #15264a;
}

body.tm-pub #side-panel-trigger {
  color: white !important;
}
body.tm-pub #side-panel {
  background-color: black;
}
body.tm-pub #side-panel nav.primary-nav .ul1 .li1 .a1 {
  color: white !important;
}
body.tm-pub ::selection {
  background: #AC2225;
}
body.tm-pub a {
  color: #AC2225;
}
body.tm-pub .page-menu.page-menu-active nav {
  background-color: black !important;
}
body.tm-pub .page-menu.page-menu-active nav ul li a {
  color: white;
}
body.tm-pub .subnav-container {
  background: #AC2225;
}
body.tm-pub .subnav-container .page-title {
  background: black;
  color: white;
}
body.tm-pub .subnav-container #menu-responsive-icon {
  background-color: #AC2225 !important;
  color: white;
}
body.tm-pub .subnav-container #menu-responsive-icon:before {
  background-color: #AC2225;
}
body.tm-pub .subnav-container nav ul li a {
  color: white;
}
body.tm-pub .subnav-container nav ul li:hover a, body.tm-pub .subnav-container nav ul li:active a {
  color: white;
}
body.tm-pub .subnav-container nav ul li:hover a:after, body.tm-pub .subnav-container nav ul li:active a:after {
  background-color: white;
}
body.tm-pub .subnav-container nav ul li a.active {
  background-color: black !important;
  color: white;
}
body.tm-pub .ball-beat > div,
body.tm-pub .ball-grid-beat > div,
body.tm-pub .ball-grid-pulse > div,
body.tm-pub .ball-pulse-sync > div,
body.tm-pub .ball-rotate > div,
body.tm-pub .ball-rotate > div:after,
body.tm-pub .ball-rotate > div:before,
body.tm-pub .ball-scale-multiple > div,
body.tm-pub .ball-scale-ripple > div,
body.tm-pub .ball-spin-fade-loader > div,
body.tm-pub .ball-triangle-path > div,
body.tm-pub .ball-zig-zag > div,
body.tm-pub .cube-transition > div,
body.tm-pub .line-scale-pulse-out-rapid > div,
body.tm-pub .line-scale > div,
body.tm-pub .square-spin > div {
  background: #AC2225;
}
body.tm-pub .tm-pub.call-outs .call-out {
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
body.tm-pub .tm-pub.call-outs .call-out:nth-child(1n) {
  background: url("/themes/Conns/assets/dist/images/img-landingPage-TrishandMarys-history.jpg");
}
body.tm-pub .tm-pub.call-outs .call-out:nth-child(2n) {
  background: url("/themes/Conns/assets/dist/images/img-landingPage-TrishandMarys-photoGallery.jpg");
}
body.tm-pub .tm-pub.call-outs .call-out:nth-child(3n) {
  background: url("/themes/Conns/assets/dist/images/img-landingPage-TrishandMarys-contactUs.jpg");
}
body.tm-pub .tm-pub.call-outs .call-out a {
  color: #000;
}
body.tm-pub .tm-pub.call-outs .call-out a h2 {
  font-weight: 700;
}
body.tm-pub .tm-pub.call-outs .call-out a:hover, body.tm-pub .tm-pub.call-outs .call-out a:focus {
  color: #000;
}
body.tm-pub .location-info .widget span {
  color: #AC2225;
}
body.tm-pub .location-info .widget a {
  color: #AC2225;
  font-size: 16px;
}
body.tm-pub .location-info .widget .logo img {
  max-width: 300px;
}
body.tm-pub .socials {
  position: absolute;
  top: 25%;
  right: -8%;
}
body.tm-pub .socials a {
  color: #AC2225 !important;
}
body.tm-pub .socials a:hover {
  color: #401e1f !important;
}
body.tm-pub footer.footer-light {
  border-top: 1px solid #EAC288 !important;
  background-color: #AC2225 !important;
}
body.tm-pub footer.footer-light .footer-content {
  color: white !important;
}
body.tm-pub footer.footer-light .footer-content .widget span {
  color: white !important;
}
body.tm-pub footer.footer-light .footer-content .widget span a {
  color: white !important;
}
body.tm-pub footer.footer-light .footer-content .widget ul.list-icon li span:before {
  color: white !important;
}
body.tm-pub footer.footer-light .footer-content .widget ul.list-icon li strong {
  color: white !important;
}
body.tm-pub footer.footer-light .footer-content a {
  color: white !important;
}
body.tm-pub .background-overlay .parallax-container {
  background-position: center center !important;
}
body.tm-pub input[type=submit] {
  background-color: #AC2225;
}
body.tm-pub input[type=submit]:hover {
  background-color: #401e1f;
}

body.inn #side-panel-trigger {
  color: #a0ba9f !important;
}
body.inn #side-panel {
  background-color: #204825;
}
body.inn #side-panel nav.primary-nav .ul1 .li1 .a1 {
  color: white !important;
}
body.inn ::selection {
  background: #2d6633;
}
body.inn a {
  color: #2d6633;
  font-weight: 400;
}
body.inn a.back-to {
  display: block;
  padding-bottom: 20px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
}
body.inn a.back-to:before {
  position: relative;
  display: inline-block;
  padding-right: 5px;
  content: "\f060";
  font-weight: 700;
  font-size: 12px;
  font-family: "Font Awesome 5 Free";
}
body.inn .page-menu.page-menu-active nav {
  background-color: #204825 !important;
}
body.inn .page-menu.page-menu-active nav ul li:hover a {
  color: white;
  text-decoration: underline;
}
body.inn .page-menu.page-menu-active nav ul li a {
  color: white;
}
body.inn .page-menu.page-menu-active nav ul li:last-of-type a {
  background: inherit !important;
}
body.inn .subnav-container {
  background: #e5e2dd;
}
body.inn .subnav-container .page-title {
  background: #2d6633;
  color: white;
}
body.inn .subnav-container #menu-responsive-icon {
  background-color: #2d6633 !important;
  color: #204825;
}
body.inn .subnav-container #menu-responsive-icon:before {
  background-color: #e5e2dd;
}
body.inn .subnav-container nav ul li:hover a, body.inn .subnav-container nav ul li:active a {
  color: #2d6633;
}
body.inn .subnav-container nav ul li:hover a:after, body.inn .subnav-container nav ul li:active a:after {
  background-color: #204825;
}
body.inn .subnav-container nav ul li a.active {
  background-color: #204825 !important;
  color: white;
}
body.inn .subnav-container nav ul li:last-of-type a {
  background: #2d6633 !important;
  color: white !important;
}
body.inn .subnav-container nav ul li:last-of-type a:after {
  background-color: white;
}
body.inn .ball-beat > div,
body.inn .ball-grid-beat > div,
body.inn .ball-grid-pulse > div,
body.inn .ball-pulse-sync > div,
body.inn .ball-rotate > div,
body.inn .ball-rotate > div:after,
body.inn .ball-rotate > div:before,
body.inn .ball-scale-multiple > div,
body.inn .ball-scale-ripple > div,
body.inn .ball-spin-fade-loader > div,
body.inn .ball-triangle-path > div,
body.inn .ball-zig-zag > div,
body.inn .cube-transition > div,
body.inn .line-scale-pulse-out-rapid > div,
body.inn .line-scale > div,
body.inn .square-spin > div {
  background: #2d6633;
}
body.inn .call-to-action.book {
  background: #e5e2dd;
}
body.inn .call-to-action.book h2,
body.inn .call-to-action.book h3 {
  font-size: 29px;
}
body.inn .call-to-action.book h2 span,
body.inn .call-to-action.book h3 span {
  font-size: 21px;
}
body.inn .call-to-action.book a {
  text-transform: none !important;
}
body.inn .call-to-action.book a span {
  margin-left: 10px;
}
body.inn .call-to-action span {
  color: #2d6633 !important;
}
body.inn .call-to-action .btn.btn-light:hover, body.inn .call-to-action .btn.btn-light:focus {
  border: #2d6633 !important;
  background: #2d6633 !important;
}
body.inn .btn.btn-creative {
  background: #2d6633;
  color: white;
}
body.inn .inn.call-outs .call-out {
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
body.inn .inn.call-outs .call-out:nth-child(1n) {
  background: url("/themes/Conns/assets/dist/images/img-innAt835-storyOf835.jpg");
}
body.inn .inn.call-outs .call-out:nth-child(2n) {
  background: url("/themes/Conns/assets/dist/images/img-innAt835-photoGallery.jpg");
}
body.inn .inn.call-outs .call-out:nth-child(3n) {
  background: url("/themes/Conns/assets/dist/images/img-innAt835-contactUs.jpg");
}
body.inn .location-info .widget span {
  color: #2d6633;
}
body.inn .location-info .widget a {
  color: #204825;
  font-size: 16px;
}
body.inn .socials {
  position: absolute;
  top: 25%;
  right: -8%;
}
body.inn .socials a {
  color: #2d6633 !important;
}
body.inn .socials a:hover {
  color: #204825 !important;
}
body.inn footer.footer-light {
  border-top: 1px solid #e5e2dd !important;
  background-color: #a0ba9f !important;
}
body.inn footer.footer-light .footer-content {
  color: black !important;
}
body.inn footer.footer-light .footer-content .widget span {
  color: black !important;
}
body.inn footer.footer-light .footer-content .widget span a {
  color: black !important;
}
body.inn footer.footer-light .footer-content .widget ul.list-icon li span:before {
  color: black !important;
}
body.inn footer.footer-light .footer-content .widget ul.list-icon li strong {
  color: black !important;
}
body.inn footer.footer-light .footer-content a {
  color: black !important;
}
body.inn .background-overlay .parallax-container {
  background-position: center center !important;
}
body.inn .carousel {
  padding: 0;
}
@media only screen and (max-width: 991px) {
  body.inn .carousel {
    display: none;
  }
}
body.inn .carousel .portfolio-item {
  padding: 0;
}
body.inn .carousel .portfolio-item .portfolio-image:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  content: " ";
}
body.inn .carousel .portfolio-item .portfolio-description a > span {
  font-size: 14px !important;
}
body.inn .carousel .portfolio-item .portfolio-description a span {
  width: 40px;
  height: 40px;
  border: 1px solid #ededed;
  border-radius: 50%;
  background-color: #ffffff;
  color: #444444;
  line-height: 40px;
  transition: all 250ms ease-in-out 0s;
}
body.inn .carousel .portfolio-item .portfolio-description a:hover i,
body.inn .carousel .portfolio-item .portfolio-description a:hover span {
  border-color: #a0ba9f;
  background-color: #a0ba9f;
  color: white;
}
body.inn .carousel .owl-controls .owl-nav [class*=owl-] span {
  font-size: 22px;
  line-height: 42px;
}
body.inn .carousel .owl-controls .owl-nav .owl-prev:hover,
body.inn .carousel .owl-controls .owl-nav .owl-next:hover {
  border-color: #a0ba9f;
  background-color: #a0ba9f;
  color: white;
}
body.inn .carousel .owl-dots {
  display: none !important;
}
body.inn input[type=submit] {
  background-color: #204825;
}
body.inn input[type=submit]:hover {
  background-color: #a0ba9f;
}

body.gillett-farm #side-panel-trigger {
  color: #00818a !important;
}
body.gillett-farm #side-panel {
  background-color: #00818a;
}
body.gillett-farm #side-panel nav.primary-nav .ul1 .li1 .a1 {
  color: white !important;
}
body.gillett-farm ::selection {
  background: #00818a;
}
body.gillett-farm a {
  color: #00818a;
}
body.gillett-farm .page-menu.page-menu-active nav {
  background-color: #00818a !important;
}
body.gillett-farm .page-menu.page-menu-active nav ul li a {
  color: white;
}
body.gillett-farm .subnav-container {
  background: #c8bfb6;
}
body.gillett-farm .subnav-container .page-title {
  background: #00818a;
  color: white;
}
body.gillett-farm .subnav-container #menu-responsive-icon {
  background-color: #00818a !important;
  color: white;
}
body.gillett-farm .subnav-container #menu-responsive-icon:before {
  background-color: #c8bfb6;
}
body.gillett-farm .subnav-container nav ul li:hover a, body.gillett-farm .subnav-container nav ul li:active a {
  color: #a50034;
}
body.gillett-farm .subnav-container nav ul li:hover a:after, body.gillett-farm .subnav-container nav ul li:active a:after {
  background-color: #a50034;
}
body.gillett-farm .subnav-container nav ul li a.active {
  background-color: #00818a !important;
  color: white;
}
body.gillett-farm .ball-beat > div,
body.gillett-farm .ball-grid-beat > div,
body.gillett-farm .ball-grid-pulse > div,
body.gillett-farm .ball-pulse-sync > div,
body.gillett-farm .ball-rotate > div,
body.gillett-farm .ball-rotate > div:after,
body.gillett-farm .ball-rotate > div:before,
body.gillett-farm .ball-scale-multiple > div,
body.gillett-farm .ball-scale-ripple > div,
body.gillett-farm .ball-spin-fade-loader > div,
body.gillett-farm .ball-triangle-path > div,
body.gillett-farm .ball-zig-zag > div,
body.gillett-farm .cube-transition > div,
body.gillett-farm .line-scale-pulse-out-rapid > div,
body.gillett-farm .line-scale > div,
body.gillett-farm .square-spin > div {
  background: #00818a;
}
body.gillett-farm .gillett-farm.call-outs .call-out {
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
body.gillett-farm .gillett-farm.call-outs .call-out:nth-child(1n) {
  background: url("/themes/Conns/assets/dist/images/img-gillettFarm-storyOfGillettFarm.jpg");
}
body.gillett-farm .gillett-farm.call-outs .call-out:nth-child(2n) {
  background: url("/themes/Conns/assets/dist/images/img-gillettFarm-photoGallery.jpg");
}
body.gillett-farm .gillett-farm.call-outs .call-out:nth-child(3n) {
  background: url("/themes/Conns/assets/dist/images/img-gillettFarm-contactUs.jpg");
}
body.gillett-farm .location-info .widget span {
  color: #00818a;
}
body.gillett-farm .location-info .widget a {
  color: #a50034;
  font-size: 16px;
}
body.gillett-farm .socials {
  position: absolute;
  top: 25%;
  right: -8%;
}
body.gillett-farm .socials a {
  color: #00818a !important;
}
body.gillett-farm .socials a:hover {
  color: #a50034 !important;
}
body.gillett-farm footer.footer-light {
  border-top: 1px solid #c8bfb6 !important;
  background-color: #00818a !important;
}
body.gillett-farm footer.footer-light .footer-content {
  color: white !important;
}
body.gillett-farm footer.footer-light .footer-content .widget span {
  color: white !important;
}
body.gillett-farm footer.footer-light .footer-content .widget span a {
  color: white !important;
}
body.gillett-farm footer.footer-light .footer-content .widget ul.list-icon li span:before {
  color: white !important;
}
body.gillett-farm footer.footer-light .footer-content .widget ul.list-icon li strong {
  color: white !important;
}
body.gillett-farm footer.footer-light .footer-content a {
  color: white !important;
}
body.gillett-farm .background-overlay .parallax-container {
  background-position: center center !important;
}
body.gillett-farm input[type=submit] {
  background-color: #00818a;
}
body.gillett-farm input[type=submit]:hover {
  background-color: #a50034;
}

body.merchant-house #side-panel-trigger {
  color: white !important;
}
body.merchant-house #side-panel {
  background-color: #e22f22;
}
body.merchant-house #side-panel nav.primary-nav .ul1 .li1 .a1 {
  color: white !important;
}
body.merchant-house ::selection {
  background: #e22f22;
}
body.merchant-house a {
  color: #e22f22;
}
body.merchant-house .page-menu.page-menu-active nav {
  background-color: #53575a !important;
}
body.merchant-house .page-menu.page-menu-active nav ul li a {
  color: white;
}
body.merchant-house .subnav-container {
  background: #53575a;
}
body.merchant-house .subnav-container .page-title {
  background: #e22f22;
  color: white;
}
body.merchant-house .subnav-container #menu-responsive-icon {
  background-color: #53575a !important;
  color: white;
}
body.merchant-house .subnav-container #menu-responsive-icon:before {
  background-color: #53575a;
}
body.merchant-house .subnav-container nav ul li a {
  color: white;
}
body.merchant-house .subnav-container nav ul li:hover a, body.merchant-house .subnav-container nav ul li:active a {
  color: #e22f22;
}
body.merchant-house .subnav-container nav ul li:hover a:after, body.merchant-house .subnav-container nav ul li:active a:after {
  background-color: #e22f22;
}
body.merchant-house .ball-beat > div,
body.merchant-house .ball-grid-beat > div,
body.merchant-house .ball-grid-pulse > div,
body.merchant-house .ball-pulse-sync > div,
body.merchant-house .ball-rotate > div,
body.merchant-house .ball-rotate > div:after,
body.merchant-house .ball-rotate > div:before,
body.merchant-house .ball-scale-multiple > div,
body.merchant-house .ball-scale-ripple > div,
body.merchant-house .ball-spin-fade-loader > div,
body.merchant-house .ball-triangle-path > div,
body.merchant-house .ball-zig-zag > div,
body.merchant-house .cube-transition > div,
body.merchant-house .line-scale-pulse-out-rapid > div,
body.merchant-house .line-scale > div,
body.merchant-house .square-spin > div {
  background: #e22f22;
}
body.merchant-house .merchant-house.call-outs .call-out {
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
body.merchant-house .merchant-house.call-outs .call-out:nth-child(1n) {
  background: url("/themes/Conns/assets/dist/images/img-merchantHouse-storyOfMH.jpg");
}
body.merchant-house .merchant-house.call-outs .call-out:nth-child(2n) {
  background: url("/themes/Conns/assets/dist/images/img-obeds-photoGallery.jpg");
}
body.merchant-house .merchant-house.call-outs .call-out:nth-child(3n) {
  background: url("/themes/Conns/assets/dist/images/img-obeds-contactUs.jpg");
}
body.merchant-house .location-info .widget span {
  color: #e22f22;
}
body.merchant-house .location-info .widget a {
  color: #e22f22;
  font-size: 16px;
}
body.merchant-house .socials {
  position: absolute;
  top: 25%;
  right: -8%;
}
body.merchant-house .socials a {
  color: #e22f22 !important;
}
body.merchant-house .socials a:hover {
  color: #53575a !important;
}
body.merchant-house footer.footer-light {
  border-top: 1px solid #53575a !important;
  background-color: #e22f22 !important;
}
body.merchant-house footer.footer-light .footer-content {
  color: white !important;
}
body.merchant-house footer.footer-light .footer-content .widget span {
  color: white !important;
}
body.merchant-house footer.footer-light .footer-content .widget span a {
  color: white !important;
}
body.merchant-house footer.footer-light .footer-content .widget ul.list-icon li span:before {
  color: white !important;
}
body.merchant-house footer.footer-light .footer-content .widget ul.list-icon li strong {
  color: white !important;
}
body.merchant-house footer.footer-light .footer-content a {
  color: white !important;
}
body.merchant-house .background-overlay .parallax-container {
  background-position: center center !important;
}
body.merchant-house input[type=submit] {
  background-color: #e22f22;
}
body.merchant-house input[type=submit]:hover {
  background-color: #53575a;
}

body.elkhart-station #side-panel-trigger {
  color: white !important;
}
body.elkhart-station #side-panel {
  background-color: #778164;
}
body.elkhart-station #side-panel nav.primary-nav .ul1 .li1 .a1 {
  color: white !important;
}
body.elkhart-station ::selection {
  background: #778164;
}
body.elkhart-station a {
  color: #778164;
}
body.elkhart-station .page-menu.page-menu-active nav {
  background-color: #6b8592 !important;
}
body.elkhart-station .page-menu.page-menu-active nav ul li a {
  color: white;
}
body.elkhart-station .subnav-container {
  background: #6b8592;
}
body.elkhart-station .subnav-container .page-title {
  background: black;
  color: white;
}
body.elkhart-station .subnav-container #menu-responsive-icon {
  background-color: #6b8592 !important;
  color: white;
}
body.elkhart-station .subnav-container #menu-responsive-icon:before {
  background-color: #6b8592;
}
body.elkhart-station .subnav-container nav ul li a {
  color: white;
}
body.elkhart-station .subnav-container nav ul li:hover a, body.elkhart-station .subnav-container nav ul li:focus a {
  color: white;
}
body.elkhart-station .subnav-container nav ul li:hover a:after, body.elkhart-station .subnav-container nav ul li:focus a:after {
  background-color: white;
}
body.elkhart-station .subnav-container nav ul li a.active {
  background-color: black !important;
  color: white;
}
body.elkhart-station .subnav-container nav ul li a.active:hover, body.elkhart-station .subnav-container nav ul li a.active:focus {
  color: white !important;
}
body.elkhart-station .ball-beat > div,
body.elkhart-station .ball-grid-beat > div,
body.elkhart-station .ball-grid-pulse > div,
body.elkhart-station .ball-pulse-sync > div,
body.elkhart-station .ball-rotate > div,
body.elkhart-station .ball-rotate > div:after,
body.elkhart-station .ball-rotate > div:before,
body.elkhart-station .ball-scale-multiple > div,
body.elkhart-station .ball-scale-ripple > div,
body.elkhart-station .ball-spin-fade-loader > div,
body.elkhart-station .ball-triangle-path > div,
body.elkhart-station .ball-zig-zag > div,
body.elkhart-station .cube-transition > div,
body.elkhart-station .line-scale-pulse-out-rapid > div,
body.elkhart-station .line-scale > div,
body.elkhart-station .square-spin > div {
  background: #6b8592;
}
body.elkhart-station .elkhart-station.call-outs .call-out {
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
body.elkhart-station .elkhart-station.call-outs .call-out:nth-child(1n) {
  background: url("/themes/Conns/assets/dist/images/img-elkhart-station-storyOfElkhartStation.jpg");
}
body.elkhart-station .elkhart-station.call-outs .call-out:nth-child(2n) {
  background: url("/themes/Conns/assets/dist/images/img-elkhart-station-photoGallery.jpg");
}
body.elkhart-station .elkhart-station.call-outs .call-out:nth-child(3n) {
  background: url("/themes/Conns/assets/dist/images/img-elkhart-station-contactUs.jpg");
}
body.elkhart-station .location-info .widget span {
  color: #4f5542;
}
body.elkhart-station .location-info .widget a {
  color: #778164;
  font-size: 16px;
}
body.elkhart-station .socials {
  position: absolute;
  top: 25%;
  right: -8%;
}
body.elkhart-station .socials a {
  color: #4f5542 !important;
}
body.elkhart-station .socials a:hover {
  color: #778164 !important;
}
body.elkhart-station footer.footer-light {
  border-top: 1px solid #4e616a !important;
  background-color: #6b8592 !important;
}
body.elkhart-station footer.footer-light .footer-content {
  color: white !important;
}
body.elkhart-station footer.footer-light .footer-content .widget span {
  color: white !important;
}
body.elkhart-station footer.footer-light .footer-content .widget span a {
  color: white !important;
}
body.elkhart-station footer.footer-light .footer-content .widget ul.list-icon li span:before {
  color: #91a8b3 !important;
}
body.elkhart-station footer.footer-light .footer-content .widget ul.list-icon li strong {
  color: white !important;
}
body.elkhart-station footer.footer-light .footer-content a {
  color: white !important;
}
body.elkhart-station .background-overlay .parallax-container {
  background-position: center center !important;
}
body.elkhart-station input[type=submit] {
  background-color: #4f5542;
}
body.elkhart-station input[type=submit]:hover {
  background-color: #778164;
}

body.vinegar-hill #side-panel-trigger {
  color: white !important;
}
body.vinegar-hill #side-panel {
  background-color: #3c5653;
}
body.vinegar-hill #side-panel nav.primary-nav .ul1 .li1 .a1 {
  color: white !important;
}
body.vinegar-hill ::selection {
  background: #3a2e2b;
}
body.vinegar-hill a {
  color: #3c5653;
}
body.vinegar-hill a:hover, body.vinegar-hill a:focus {
  color: #3a2e2b !important;
}
body.vinegar-hill .inspiro-slider .slide, body.vinegar-hill .kenburns-bg {
  background-position: center;
}
body.vinegar-hill .background-overlay .banner-logo img,
body.vinegar-hill .background-overlay-dark .banner-logo img,
body.vinegar-hill .background-overlay-light .banner-logo img {
  max-height: 575px;
}
body.vinegar-hill .page-menu.page-menu-active nav {
  background-color: #b5d6d2 !important;
}
body.vinegar-hill .page-menu.page-menu-active nav ul li a {
  color: black;
}
body.vinegar-hill .subnav-container {
  background: #b5d6d2;
}
body.vinegar-hill .subnav-container .page-title {
  background: black;
  color: white;
}
body.vinegar-hill .subnav-container #menu-responsive-icon {
  background-color: #b5d6d2 !important;
  color: black;
}
body.vinegar-hill .subnav-container #menu-responsive-icon:before {
  background-color: #b5d6d2;
}
body.vinegar-hill .subnav-container nav ul li a {
  color: black;
}
body.vinegar-hill .subnav-container nav ul li:hover a, body.vinegar-hill .subnav-container nav ul li:focus a {
  color: black;
}
body.vinegar-hill .subnav-container nav ul li:hover a:after, body.vinegar-hill .subnav-container nav ul li:focus a:after {
  background-color: black;
}
body.vinegar-hill .subnav-container nav ul li a.active {
  background-color: black !important;
  color: white;
}
body.vinegar-hill .subnav-container nav ul li a.active:hover, body.vinegar-hill .subnav-container nav ul li a.active:focus {
  color: white !important;
}
body.vinegar-hill .ball-beat > div,
body.vinegar-hill .ball-grid-beat > div,
body.vinegar-hill .ball-grid-pulse > div,
body.vinegar-hill .ball-pulse-sync > div,
body.vinegar-hill .ball-rotate > div,
body.vinegar-hill .ball-rotate > div:after,
body.vinegar-hill .ball-rotate > div:before,
body.vinegar-hill .ball-scale-multiple > div,
body.vinegar-hill .ball-scale-ripple > div,
body.vinegar-hill .ball-spin-fade-loader > div,
body.vinegar-hill .ball-triangle-path > div,
body.vinegar-hill .ball-zig-zag > div,
body.vinegar-hill .cube-transition > div,
body.vinegar-hill .line-scale-pulse-out-rapid > div,
body.vinegar-hill .line-scale > div,
body.vinegar-hill .square-spin > div {
  background: #3c5653;
}
body.vinegar-hill .vinegar-hill.call-outs .call-out {
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
body.vinegar-hill .vinegar-hill.call-outs .call-out:nth-child(1n) {
  background: url("/themes/Conns/assets/dist/images/img-vinegar-hill-storyOfVinegarHill.jpg");
}
body.vinegar-hill .vinegar-hill.call-outs .call-out:nth-child(2n) {
  background: url("/themes/Conns/assets/dist/images/img-vinegar-hill-photoGallery.jpg");
}
body.vinegar-hill .vinegar-hill.call-outs .call-out:nth-child(3n) {
  background: url("/themes/Conns/assets/dist/images/img-vinegar-hill-contactUs.jpg");
}
body.vinegar-hill .location-info .widget a {
  color: #3c5653;
  font-size: 16px;
}
body.vinegar-hill .location-info .widget a:hover, body.vinegar-hill .location-info .widget a:focus {
  color: #3a2e2b !important;
}
body.vinegar-hill .location-info .widget a:hover span:before, body.vinegar-hill .location-info .widget a:focus span:before {
  color: #3a2e2b !important;
}
body.vinegar-hill .socials {
  position: absolute;
  top: 25%;
  right: -8%;
}
body.vinegar-hill .socials a {
  color: #3c5653 !important;
}
body.vinegar-hill .socials a:hover, body.vinegar-hill .socials a:focus {
  color: #3a2e2b !important;
}
body.vinegar-hill footer.footer-light {
  border-top: 1px solid #3c5653 !important;
  background-color: #b5d6d2 !important;
}
body.vinegar-hill footer.footer-light .footer-content {
  color: #3c5653 !important;
}
body.vinegar-hill footer.footer-light .footer-content .widget span {
  color: #3c5653 !important;
}
body.vinegar-hill footer.footer-light .footer-content .widget span a {
  color: #3c5653 !important;
}
body.vinegar-hill footer.footer-light .footer-content .widget ul.list-icon li span:before {
  color: #3c5653 !important;
}
body.vinegar-hill footer.footer-light .footer-content .widget ul.list-icon li strong {
  color: #3c5653 !important;
}
body.vinegar-hill footer.footer-light .footer-content a {
  color: #3c5653 !important;
}
body.vinegar-hill .background-overlay .parallax-container {
  background-position: center center !important;
}
body.vinegar-hill input[type=submit] {
  background-color: #AC2225;
}
body.vinegar-hill input[type=submit]:hover {
  background-color: #401e1f;
}

body.wm-vans #side-panel-trigger {
  color: #c8bfb6 !important;
}
body.wm-vans #side-panel {
  background-color: #401e1f;
}
body.wm-vans #side-panel nav.primary-nav .ul1 .li1 .a1 {
  color: white !important;
}
body.wm-vans ::selection {
  background: #ab122e;
}
body.wm-vans a {
  color: #ab122e;
}
body.wm-vans .page-menu.page-menu-active nav {
  background-color: #401e1f !important;
}
body.wm-vans .page-menu.page-menu-active nav ul li a {
  color: white;
}
body.wm-vans .subnav-container {
  background: #c8bfb6;
}
body.wm-vans .subnav-container .page-title {
  background: #ab122e;
  color: white;
}
body.wm-vans .subnav-container #menu-responsive-icon {
  background-color: #ab122e !important;
  color: white;
}
body.wm-vans .subnav-container #menu-responsive-icon:before {
  background-color: #c8bfb6;
}
body.wm-vans .subnav-container nav ul li:hover a, body.wm-vans .subnav-container nav ul li:active a {
  color: #ab122e;
}
body.wm-vans .subnav-container nav ul li:hover a:after, body.wm-vans .subnav-container nav ul li:active a:after {
  background-color: #ab122e;
}
body.wm-vans .subnav-container nav ul li a.active {
  background-color: #401e1f !important;
  color: white;
}
body.wm-vans .ball-beat > div,
body.wm-vans .ball-grid-beat > div,
body.wm-vans .ball-grid-pulse > div,
body.wm-vans .ball-pulse-sync > div,
body.wm-vans .ball-rotate > div,
body.wm-vans .ball-rotate > div:after,
body.wm-vans .ball-rotate > div:before,
body.wm-vans .ball-scale-multiple > div,
body.wm-vans .ball-scale-ripple > div,
body.wm-vans .ball-spin-fade-loader > div,
body.wm-vans .ball-triangle-path > div,
body.wm-vans .ball-zig-zag > div,
body.wm-vans .cube-transition > div,
body.wm-vans .line-scale-pulse-out-rapid > div,
body.wm-vans .line-scale > div,
body.wm-vans .square-spin > div {
  background: #ab122e;
}
body.wm-vans .wm-vans.call-outs .call-out {
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
body.wm-vans .wm-vans.call-outs .call-out:nth-child(1n) {
  background: url("/themes/Conns/assets/dist/images/img-wmVans-storyOfWmVans.jpg");
}
body.wm-vans .wm-vans.call-outs .call-out:nth-child(2n) {
  background: url("/themes/Conns/assets/dist/images/img-wmVans-photoGallery.jpg");
}
body.wm-vans .wm-vans.call-outs .call-out:nth-child(3n) {
  background: url("/themes/Conns/assets/dist/images/img-wmVans-contactUs.jpg");
}
body.wm-vans .location-info .widget span {
  color: #ab122e;
}
body.wm-vans .location-info .widget a {
  color: #ab122e;
  font-size: 16px;
}
body.wm-vans .socials {
  position: absolute;
  top: 25%;
  right: -8%;
}
body.wm-vans .socials a {
  color: #ab122e !important;
}
body.wm-vans .socials a:hover {
  color: #401e1f !important;
}
body.wm-vans footer.footer-light {
  border-top: 1px solid #c8bfb6 !important;
  background-color: #ab122e !important;
}
body.wm-vans footer.footer-light .footer-content {
  color: white !important;
}
body.wm-vans footer.footer-light .footer-content .widget span {
  color: white !important;
}
body.wm-vans footer.footer-light .footer-content .widget span a {
  color: white !important;
}
body.wm-vans footer.footer-light .footer-content .widget ul.list-icon li span:before {
  color: white !important;
}
body.wm-vans footer.footer-light .footer-content .widget ul.list-icon li strong {
  color: white !important;
}
body.wm-vans footer.footer-light .footer-content a {
  color: white !important;
}
body.wm-vans .background-overlay .parallax-container {
  background-position: center center !important;
}
body.wm-vans input[type=submit] {
  background-color: #ab122e;
}
body.wm-vans input[type=submit]:hover {
  background-color: #401e1f;
}

body.cidery #side-panel-trigger {
  color: white !important;
}
body.cidery #side-panel {
  background-color: black;
}
body.cidery #side-panel nav.primary-nav .ul1 .li1 .a1 {
  color: white !important;
}
body.cidery ::selection {
  background: black;
}
body.cidery a {
  color: #485248;
  font-weight: 600;
}
body.cidery a:hover, body.cidery a:focus {
  color: black !important;
}
body.cidery .inspiro-slider .slide, body.cidery .kenburns-bg {
  background-position: center;
}
body.cidery .background-overlay .banner-logo img,
body.cidery .background-overlay-dark .banner-logo img,
body.cidery .background-overlay-light .banner-logo img {
  max-height: 575px;
}
body.cidery .page-menu.page-menu-active nav {
  background-color: #98a999 !important;
}
body.cidery .page-menu.page-menu-active nav ul li a {
  color: black;
}
body.cidery .subnav-container {
  background: #98a999;
}
body.cidery .subnav-container .page-title {
  background: black;
  color: white;
}
body.cidery .subnav-container #menu-responsive-icon {
  background-color: #98a999 !important;
  color: black;
}
body.cidery .subnav-container #menu-responsive-icon:before {
  background-color: #98a999;
}
body.cidery .subnav-container nav ul li a {
  color: black;
}
body.cidery .subnav-container nav ul li:hover a, body.cidery .subnav-container nav ul li:focus a {
  color: black;
}
body.cidery .subnav-container nav ul li:hover a:after, body.cidery .subnav-container nav ul li:focus a:after {
  background-color: black;
}
body.cidery .subnav-container nav ul li a.active {
  background-color: black !important;
  color: white;
}
body.cidery .subnav-container nav ul li a.active:hover, body.cidery .subnav-container nav ul li a.active:focus {
  color: white !important;
}
body.cidery .ball-beat > div,
body.cidery .ball-grid-beat > div,
body.cidery .ball-grid-pulse > div,
body.cidery .ball-pulse-sync > div,
body.cidery .ball-rotate > div,
body.cidery .ball-rotate > div:after,
body.cidery .ball-rotate > div:before,
body.cidery .ball-scale-multiple > div,
body.cidery .ball-scale-ripple > div,
body.cidery .ball-spin-fade-loader > div,
body.cidery .ball-triangle-path > div,
body.cidery .ball-zig-zag > div,
body.cidery .cube-transition > div,
body.cidery .line-scale-pulse-out-rapid > div,
body.cidery .line-scale > div,
body.cidery .square-spin > div {
  background: #485248;
}
body.cidery .cidery.call-outs .call-out {
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
body.cidery .cidery.call-outs .call-out:nth-child(1n) {
  background: url("/themes/Conns/assets/dist/images/img-landingPage-7thStreetCidery-history.jpg");
}
body.cidery .cidery.call-outs .call-out:nth-child(2n) {
  background: url("/themes/Conns/assets/dist/images/img-landingPage-7thStreetCidery-photoGallery.jpg");
}
body.cidery .cidery.call-outs .call-out:nth-child(3n) {
  background: url("/themes/Conns/assets/dist/images/img-landingPage-7thStreetCidery-contactUs.jpg");
}
body.cidery .location-info .widget h3 span::before {
  color: #485248;
}
body.cidery .location-info .widget a {
  color: #485248;
  font-size: 16px;
  font-weight: 400;
}
body.cidery .location-info .widget a:hover, body.cidery .location-info .widget a:focus {
  color: black !important;
}
body.cidery .location-info .widget a:hover span:before, body.cidery .location-info .widget a:focus span:before {
  color: #485248 !important;
}
body.cidery .socials {
  position: absolute;
  top: 25%;
  right: -8%;
}
body.cidery .socials a {
  color: #485248 !important;
  font-weight: 400;
}
body.cidery .socials a:hover, body.cidery .socials a:focus {
  color: black !important;
}
body.cidery footer.footer-light {
  border-top: 1px solid #485248 !important;
  background-color: #d5e2d5 !important;
}
body.cidery footer.footer-light .footer-content {
  color: #485248 !important;
}
body.cidery footer.footer-light .footer-content .widget span {
  color: #485248 !important;
}
body.cidery footer.footer-light .footer-content .widget span a {
  color: #485248 !important;
  font-weight: 400;
}
body.cidery footer.footer-light .footer-content .widget ul.list-icon li span:before {
  color: #485248 !important;
}
body.cidery footer.footer-light .footer-content .widget ul.list-icon li strong {
  color: #485248 !important;
}
body.cidery footer.footer-light .footer-content a {
  color: #485248 !important;
  font-weight: 400;
}
body.cidery .background-overlay .parallax-container {
  background-position: center center !important;
}
body.cidery input[type=submit] {
  background-color: #98a999;
}
body.cidery input[type=submit]:hover {
  background-color: #485248;
}

.parallax-container {
  background-position: center center !important;
}

#page-title {
  border: none;
  background: url(/themes/Conns/assets/dist/images/Conns-About-background.png);
}

.background-overlay img,
.background-overlay-dark img,
.background-overlay-light img {
  position: absolute;
  top: 0;
  z-index: -1;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  opacity: 1;
}
.background-overlay .banner-logo img,
.background-overlay-dark .banner-logo img,
.background-overlay-light .banner-logo img {
  position: relative !important;
  z-index: 0;
  margin: auto;
  padding: 25px;
  min-width: auto;
  max-width: 100%;
  max-height: 400px;
  opacity: 1 !important;
}

.background-overlay-dark:before {
  background: rgba(0, 0, 0, 0.5);
}

.inspiro-slider .slide-captions h1 {
  background: transparent;
}

ul.menu-list {
  display: flex;
  margin: 0 -15px;
  margin-bottom: 100px;
  padding: 0;
  width: 100%;
  list-style: none;
  flex-wrap: wrap;
}
ul.menu-list .menu-item {
  position: relative;
  overflow: hidden;
  padding: 15px;
  width: 33%;
  border-radius: 10px;
}
@media only screen and (max-width: 992px) {
  ul.menu-list .menu-item {
    width: 50%;
  }
}
@media only screen and (max-width: 640px) {
  ul.menu-list .menu-item {
    width: 100%;
  }
}
ul.menu-list .menu-item h3,
ul.menu-list .menu-item .title {
  margin-bottom: 10px;
  padding-right: 55px;
  font-weight: 600;
  font-size: 22px;
  font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
  line-height: 32px;
}
@media only screen and (max-width: 640px) {
  ul.menu-list .menu-item h3,
ul.menu-list .menu-item .title {
    font-size: 1.35714286em;
  }
}
ul.menu-list .menu-item .price {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 12px;
}

.events .eventmonth,
.events .categoryFilter {
  display: none;
}
.events .event {
  text-align: left;
}
.events .event img {
  margin: 0 auto;
  padding-bottom: 20px;
  width: 100%;
}
.events .event h3.title {
  margin-bottom: 0;
  margin-bottom: 16px;
  color: #444444;
  letter-spacing: -0.1px;
  font-weight: 600;
  font-size: 18px;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  line-height: 23px;
}
.events .event p {
  color: #757575;
  text-transform: none;
  letter-spacing: 0;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  font-family: open sans;
  line-height: 28px;
}
.events .event .post-meta-date,
.events .event .post-meta-category {
  color: #757575 !important;
}
.events .event .ical {
  color: initial;
  font-size: 12px;
}

.content-form fieldset {
  padding: 0.35em 0.75em 0.625em;
}
.content-form .required label:after {
  font-weight: 700;
  font-family: "Font Awesome 5 Free";
}
.content-form label {
  display: block;
  clear: none;
  margin-top: 8px;
  margin-bottom: 4px;
  color: #444444;
  text-transform: uppercase;
  font-size: 13px;
  font-family: open sans, Helvetica, Arial, sans-serif;
  cursor: pointer;
}
.content-form input[type=radio] {
  position: relative;
  top: 3px;
  float: left;
  margin-right: 10px;
  appearance: radio;
}
.content-form input[type=checkbox] {
  position: relative;
  top: 3px;
  float: left;
  margin-right: 10px;
  appearance: checkbox;
}
.content-form input[type=text],
.content-form input[type=date],
.content-form textarea {
  margin-bottom: 6px;
  padding: 8px 18px;
  height: auto;
  border: 2px solid #ebebeb;
  line-height: 1.33333;
}
.content-form input[type=text]:focus,
.content-form input[type=date]:focus,
.content-form textarea:focus {
  outline: 5px auto -webkit-focus-ring-color !important;
}
.content-form input[type=submit] {
  background-color: #567b95;
  text-shadow: none !important;
}
.content-form input[type=submit]:focus {
  outline: 5px auto -webkit-focus-ring-color !important;
}
.content-form input[type=submit]:hover {
  background-color: black;
}
.content-form select {
  padding: 9px 18px;
  border-radius: 0;
}
.content-form select:focus {
  outline: 5px auto -webkit-focus-ring-color !important;
}
.content-form h2 {
  padding-right: 15px;
  padding-left: 15px;
}