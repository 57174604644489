ul.menu-list {
  display: flex;
  margin: 0 -15px;
  margin-bottom: 100px;
  padding: 0;
  width: 100%;
  list-style: none;

  flex-wrap: wrap;

  .menu-item {
    position: relative;
    overflow: hidden;
    padding: 15px;
    width: 33%;
    border-radius: 10px;

    @media only screen and (max-width: 992px) {
      width: 50%;
    }

    @media only screen and (max-width: 640px) {
      width: 100%;
    }

    h3,
    .title {
      margin-bottom: 10px;
      padding-right: 55px;
      font-weight: 600;
      font-size: 22px;
      font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
      line-height: 32px;

      @media only screen and (max-width: 640px) {
        font-size: 1.35714286em;
      }
    }

    .price {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 12px;
    }
  }
}
