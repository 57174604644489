body.obeds {
  #side-panel-trigger {
    color: $obed-yellow !important;
  }

  // select {
  //   margin: 0 auto !important;
  //   min-width: 0;
  //   width: auto;
  //   background: $obed-yellow;
  //   color: #252525;
  //   text-align: center !important;
  //   text-transform: uppercase !important;
  //   font-weight: 600;
  //   font-size: 12px;
  // }

  #side-panel {
    background-color: $obed-blue;

    nav.primary-nav {
      .ul1 {
        .li1 {
          .a1 {
            color: white !important;
          }
        }
      }
    }
  }

  #portfolio.location-boxes {
    @media only screen and (max-width: 991px) {
      height: auto !important;

      .portfolio-item {
        position: relative !important;
        top: auto !important;
        left: auto !important;
        display: block;
        width: 100% !important;
        transition: none !important;
        transform: none !important;

        .portfolio-description {
          a {
            text-decoration: none;
          }

          span {
            display: block !important;
          }

          h2,
          h3 {
            padding: 20px 0 8px !important;
          }
        }
      }
    }

    .portfolio-item {
      &:nth-of-type(odd) {
        h2,
        h3 {
          background: $obed-yellow !important;
          color: $obed-blue !important;
        }

        span {
          color: $obed-blue !important;
        }

        // &:hover {
        //   h2,
        //   h3 {
        //     background: $obed-blue !important;
        //     color: white !important;
        //   }

        //   span {
        //     color: white !important;
        //   }
        // }
      }

      &:hover {
        h2,
        h3 {
          background: $obed-blue !important;
          color: white !important;
        }

        span {
          color: white !important;
        }
      }

      .portfolio-description {
        position: absolute;
        top: 0 !important;
        bottom: 0 !important;
        visibility: visible;
        padding: 0 !important;
        width: 100% !important;
        height: auto !important;
        text-align: center;
        opacity: 1;
        transform: none;

        &:hover {
          span {
            text-decoration: none;
          }
        }

        span {
          position: absolute;
          bottom: 7px;
          z-index: 1;
          display: none;
          margin: 0;
          padding: 0.875rem;
          width: 100%;
          color: $obed-blue;
          text-transform: uppercase;
          font-size: 10px;
          font-family: 'open sans';
          opacity: 1;
          transition: none;
        }

        h2,
        h3 {
          position: absolute;
          bottom: 0;
          margin: 0;
          padding: 14px;
          width: 100%;
          background: $obed-darkyellow;
          text-transform: uppercase;
          letter-spacing: 1px;
          font-weight: 700;
          font-style: normal;
          font-size: 16px;
          line-height: 18px;
        }
      }
    }
  }

  ::selection {
    background: $obed-yellow;
  }

  a {
    color: $obed-blue;
    font-weight: 400;
  }

  .background-overlay,
  .background-overlay-dark,
  .background-overlay-light {
    background: url('/themes/Conns/assets/dist/images/obed-and-isaacs-subpage-banner.png');
    background-position: center center;
    background-size: cover;
  }

  .page-menu.page-menu-active {
    nav {
      background-color: $obed-blue !important;

      ul {
        li {
          a {
            color: white;
          }
        }
      }
    }
  }

  .subnav-container {
    background: $obed-yellow;

    .page-title {
      background: $obed-blue;
      color: white;

      @media only screen and (max-width: 991px) {
        background: $obed-yellow;
        color: $obed-blue;
      }
    }

    #menu-responsive-icon {
      background-color: $obed-yellow !important;
      color: white;

      &:before {
        background-color: $obed-blue;
      }
    }

    nav {
      ul {
        li {
          &:hover,
          &:active {
            a {
              color: white;

              &:after {
                background-color: white;
              }
            }
          }

          a {
            &.active {
              background-color: $obed-blue !important;
              color: white;
            }
          }
        }
      }
    }
  }

  .ball-beat > div,
  .ball-grid-beat > div,
  .ball-grid-pulse > div,
  .ball-pulse-sync > div,
  .ball-rotate > div,
  .ball-rotate > div:after,
  .ball-rotate > div:before,
  .ball-scale-multiple > div,
  .ball-scale-ripple > div,
  .ball-spin-fade-loader > div,
  .ball-triangle-path > div,
  .ball-zig-zag > div,
  .cube-transition > div,
  .line-scale-pulse-out-rapid > div,
  .line-scale > div,
  .square-spin > div {
    background: $obed-yellow;
  }

  .obed.call-outs {
    .call-out {
      background-position: center center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;

      &:nth-child(1n) {
        background: url('/themes/Conns/assets/dist/images/img-obeds-storyOfObeds.jpg');
      }

      &:nth-child(2n) {
        background: url('/themes/Conns/assets/dist/images/img-obeds-photoGallery.jpg');
      }

      &:nth-child(3n) {
        background: url('/themes/Conns/assets/dist/images/img-obeds-contactUs.jpg');
      }
    }
  }

  .socials {
    position: absolute;
    top: 25%;
    right: -8%;

    a {
      color: $obed-blue !important;

      &:hover {
        color: $obed-yellow !important;
      }
    }
  }

  .location-picker {
    .location {
      display: inline-flex;
      margin: 20px;

      @media only screen and (max-width: 480px) {
        margin: 20px 10px;
      }

      a {
        &:hover {
          .springfield {
            background: url(/themes/Conns/assets/dist/images/btn-obeds-Springfield-on.png) no-repeat;
          }

          .peoria {
            background: url(/themes/Conns/assets/dist/images/btn-obeds-peoria-on.png) no-repeat;
          }
        }
      }

      .springfield {
        width: 120px;
        height: 120px;
        background: url(/themes/Conns/assets/dist/images/btn-obeds-Springfield.png) no-repeat;

        &.active {
          background: url(/themes/Conns/assets/dist/images/btn-obeds-Springfield-on.png) no-repeat;
        }
      }

      .peoria {
        width: 120px;
        height: 120px;
        background: url(/themes/Conns/assets/dist/images/btn-obeds-peoria.png) no-repeat;

        &.active {
          background: url(/themes/Conns/assets/dist/images/btn-obeds-peoria-on.png) no-repeat;
        }
      }

      span {
        position: relative;
        display: block;
        margin: 10px 0;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px !important;
        font-family: 'Open Sans';
        line-height: 12px;
      }
    }
  }

  .location-info {
    .widget {
      span {
        color: $obed-blue;
      }

      a {
        color: $obed-blue;
        font-size: 16px;
      }
    }
  }

  footer.footer-light {
    border-top: 1px solid $obed-brown !important;
    background-color: $obed-yellow !important;

    .footer-content {
      color: $obed-blue !important;

      .widget {
        span {
          color: $obed-gray !important;
        }

        ul.list-icon {
          li {
            span {
              &:before {
                color: $obed-blue !important;
              }
            }

            strong {
              color: $obed-blue !important;
            }
          }
        }
      }

      a {
        color: $obed-blue !important;
      }
    }
  }

  .background-overlay {
    .parallax-container {
      background-position: center center !important;
    }
  }

  input[type='submit'] {
    background-color: $obed-blue;

    &:hover {
      background-color: $obed-yellow;
      color: $obed-blue;
    }
  }
}
