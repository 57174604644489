.events {
  .eventmonth,
  .categoryFilter {
    display: none;
  }

  .event {
    text-align: left;

    img {
      margin: 0 auto;
      padding-bottom: 20px;
      width: 100%;
    }

    h3.title {
      margin-bottom: 0;
      margin-bottom: 16px;
      color: #444444;
      letter-spacing: -0.1px;
      font-weight: 600;
      font-size: 18px;
      font-family: Montserrat,Helvetica,Arial,sans-serif;
      line-height: 23px;
    }

    p {
      color: #757575;
      text-transform: none;
      letter-spacing: 0;
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      font-family: open sans;
      line-height: 28px;
    }

    .post-meta-date,
    .post-meta-category {
      color: #757575 !important;
    }

    .ical {
      color: initial;
      font-size: 12px;
    }
  }
}
