body.inn {
  #side-panel-trigger {
    color: $inn-lightgreen !important;
  }

  #side-panel {
    background-color: $inn-darkgreen;

    nav.primary-nav {
      .ul1 {
        .li1 {
          .a1 {
            color: white !important;
          }
        }
      }
    }
  }

  ::selection {
    background: $inn-mediumgreen;
  }

  a {
    color: $inn-mediumgreen;
    font-weight: 400;

    &.back-to {
      display: block;
      padding-bottom: 20px;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 12px;

      &:before {
        position: relative;
        display: inline-block;
        padding-right: 5px;
        content: '\f060';
        font-weight: 700;
        font-size: 12px;
        font-family: 'Font Awesome 5 Free';
      }
    }
  }

  .page-menu.page-menu-active {
    nav {
      background-color: $inn-darkgreen !important;

      ul {
        li {
          &:hover {
            a {
              color: white;
              text-decoration: underline;
            }
          }

          a {
            color: white;
          }

          &:last-of-type {
            a {
              background: inherit !important;
            }
          }
        }
      }
    }
  }

  .subnav-container {
    background: $inn-tan;

    .page-title {
      background: $inn-mediumgreen;
      color: white;
    }

    #menu-responsive-icon {
      background-color: $inn-mediumgreen !important;
      color: $inn-darkgreen;

      &:before {
        background-color: $inn-tan;
      }
    }

    nav {
      ul {
        li {
          &:hover,
          &:active {
            a {
              color: $inn-mediumgreen;

              &:after {
                background-color: $inn-darkgreen;
              }
            }
          }

          a {
            &.active {
              background-color: $inn-darkgreen !important;
              color: white;
            }
          }

          &:last-of-type {
            a {
              background: $inn-mediumgreen !important;
              color: white !important;

              &:after {
                background-color: white;
              }
            }
          }
        }
      }
    }
  }

  .ball-beat > div,
  .ball-grid-beat > div,
  .ball-grid-pulse > div,
  .ball-pulse-sync > div,
  .ball-rotate > div,
  .ball-rotate > div:after,
  .ball-rotate > div:before,
  .ball-scale-multiple > div,
  .ball-scale-ripple > div,
  .ball-spin-fade-loader > div,
  .ball-triangle-path > div,
  .ball-zig-zag > div,
  .cube-transition > div,
  .line-scale-pulse-out-rapid > div,
  .line-scale > div,
  .square-spin > div {
    background: $inn-mediumgreen;
  }

  .call-to-action.book {
    background: $inn-tan;

    h2,
    h3 {
      font-size: 29px;

      span {
        font-size: 21px;
      }
    }

    a {
      text-transform: none !important;

      span {
        margin-left: 10px;
      }
    }
  }

  .call-to-action {
    span {
      color: $inn-mediumgreen !important;
    }

    .btn.btn-light {
      &:hover,
      &:focus {
        border: $inn-mediumgreen !important;
        background: $inn-mediumgreen !important;
      }
    }
  }

  .btn.btn-creative {
    background: #2d6633;
    color: white;
  }

  .inn.call-outs {
    .call-out {
      background-position: center center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;

      &:nth-child(1n) {
        background: url('/themes/Conns/assets/dist/images/img-innAt835-storyOf835.jpg');
      }

      &:nth-child(2n) {
        background: url('/themes/Conns/assets/dist/images/img-innAt835-photoGallery.jpg');
      }

      &:nth-child(3n) {
        background: url('/themes/Conns/assets/dist/images/img-innAt835-contactUs.jpg');
      }
    }
  }

  .location-info {
    .widget {
      span {
        color: $inn-mediumgreen;
      }

      a {
        color: $inn-darkgreen;
        font-size: 16px;
      }
    }
  }

  .socials {
    position: absolute;
    top: 25%;
    right: -8%;

    a {
      color: $inn-mediumgreen !important;

      &:hover {
        color: $inn-darkgreen !important;
      }
    }
  }

  footer.footer-light {
    border-top: 1px solid $inn-tan !important;
    background-color: $inn-lightgreen !important;

    .footer-content {
      color: black !important;

      .widget {
        span {
          color: black !important;

          a {
            color: black !important;
          }
        }

        ul.list-icon {
          li {
            span {
              &:before {
                color: black !important;
              }
            }

            strong {
              color: black !important;
            }
          }
        }
      }

      a {
        color: black !important;
      }
    }
  }

  .background-overlay {
    .parallax-container {
      background-position: center center !important;
    }
  }

  .carousel {
    padding: 0;

    @media only screen and (max-width: 991px) {
      display: none;
    }

    .portfolio-item {
      padding: 0;

      .portfolio-image {
        &:before {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 2;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          content: ' ';
        }
      }

      .portfolio-description {
        a {
           > span {
            font-size: 14px !important;
          }

          span {
            width: 40px;
            height: 40px;
            border: 1px solid #ededed;
            border-radius: 50%;
            background-color: #ffffff;
            color: #444444;
            line-height: 40px;
            transition: all 250ms ease-in-out 0s;
          }

          &:hover {
            i,
            span {
              border-color: $inn-lightgreen;
              background-color: $inn-lightgreen;
              color: white;
            }
          }
        }
      }
    }

    .owl-controls {
      .owl-nav {
        [class*='owl-'] {
          span {
            font-size: 22px;
            line-height: 42px;
          }
        }

        .owl-prev,
        .owl-next {
          &:hover {
            border-color: $inn-lightgreen;
            background-color: $inn-lightgreen;
            color: white;
          }
        }
      }
    }

    .owl-dots {
      display: none !important;
    }
  }

  input[type='submit'] {
    background-color: $inn-darkgreen;

    &:hover {
      background-color: $inn-lightgreen;
    }
  }
}
