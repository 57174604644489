.content-form {
  fieldset {
    padding: 0.35em 0.75em 0.625em;
  }

  .required {
    label {
      &:after {
        font-weight: 700;
        font-family: 'Font Awesome 5 Free';
      }
    }
  }

  label {
    display: block;
    clear: none;
    margin-top: 8px;
    margin-bottom: 4px;
    color: #444444;
    text-transform: uppercase;
    font-size: 13px;
    font-family: open sans,Helvetica,Arial,sans-serif;
    cursor: pointer;
  }

  input[type='radio'] {
    position: relative;
    top: 3px;
    float: left;
    margin-right: 10px;

    appearance: radio;
  }

  input[type='checkbox'] {
    position: relative;
    top: 3px;
    float: left;
    margin-right: 10px;

    appearance: checkbox;
  }

  input[type='text'],
  input[type='date'],
  textarea {
    margin-bottom: 6px;
    padding: 8px 18px;
    height: auto;
    border: 2px solid #ebebeb;
    line-height: 1.33333;

    &:focus {
      outline: 5px auto -webkit-focus-ring-color !important;
    }
  }

  input[type='submit'] {
    background-color: $primary-blue;
    text-shadow: none !important;

    &:focus {
      outline: 5px auto -webkit-focus-ring-color !important;
    }

    &:hover {
      background-color: black;
    }
  }

  select {
    padding: 9px 18px;
    border-radius: 0;

    &:focus {
      outline: 5px auto -webkit-focus-ring-color !important;
    }
  }

  h2 {
    padding-right: 15px;
    padding-left: 15px;
  }
}
