$base-font-size: 100%;
// assets path
$assets: '/Portals/_default/Skins/SC/assets/dist';
// colors
$primary-blue: #567b95;

//Conn's
$conns-gray: #333333;
$conns-red: #b02e25;

//Obed & Isaac's
$obed-yellow: #e9c555;
$obed-darkyellow: #dfb427;
$obed-blue: #15264a;
$obed-gray: #353e4c;
$obed-brown: #a08055;

//Wm Van's
$wmvans-red: #ab122e;
$wmvans-darkred: #401e1f;
$wmvans-tan: #c8bfb6;

//Trish and Mary's Pub
$tmpub-red: #AC2225;
$tmpub-darkred: #401e1f;
$tmpub-tan: #EAC288;

//Vinegar Hill
$vinegarhill-light-green: #b5d6d2;
$vinegarhill-primary: #3c5653;
$vinegarhill-dark-green: #3a2e2b ;
$vinegarhill-cta: #81bab3;

//7th Street Cidery
$cidery-light-green: #d5e2d5;
$cidery-green: #98a999;
$cidery-primary: #485248;

//Elkhart Station 
$elkhartstation-primary: #6b8592;
$elkhartstation-footer-icons: #91a8b3;
$elkhartstation-footer-border: #4e616a;
$elkhartstation-cta: #9eb3bd;
$elkhartstation-info-icons: #4f5542 ;
$elkhartstation-green: #778164;

//Inn at 835
$inn-lightgreen: #a0ba9f;
$inn-mediumgreen: #2d6633;
$inn-darkgreen: #204825;
$inn-tan: #e5e2dd;

//Gillett Farm
$gillett-blue: #00818a;
$gillett-red: #a50034;
$gillett-tan: #c8bfb6;

//Conn's Catering
$connscatering-blue: #587a93;
$connscatering-brown: #564720;
$connscatering-tan: #e5e2dd;

//Merchant House
$merchanthouse-red: #e22f22;
$merchanthouse-gray: #53575a;

// fonts
