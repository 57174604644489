body.cidery {
  #side-panel-trigger {
    color: white !important;
  }

  #side-panel {
    background-color: black;

    nav.primary-nav {
      .ul1 {
        .li1 {
          .a1 {
            color: white !important;
          }
        }
      }
    }
  }

  ::selection {
    background: black;
  }

  a {
    color: $cidery-primary;
    font-weight: 600;

    &:hover,
    &:focus {
      color: black !important;
    }
  }

  .inspiro-slider .slide, .kenburns-bg {
    background-position: center;
  }

  .background-overlay,
  .background-overlay-dark,
  .background-overlay-light {

    .banner-logo img {
      max-height: 575px;

    }
  }

  .page-menu.page-menu-active {
    nav {
      background-color: $cidery-green !important;

      ul {
        li {
          a {
            color: black;
          }
        }
      }
    }
  }

  .subnav-container {
    background: $cidery-green;

    .page-title {
      background: black;
      color: white;
    }

    #menu-responsive-icon {
      background-color: $cidery-green !important;
      color: black;

      &:before {
        background-color: $cidery-green;
      }
    }

    nav {
      ul {
        li {

          a {
            color: black;
          }

          &:hover,
          &:focus {
            a {
              color: black;

              &:after {
                background-color: black;
              }
            }
          }

          a {
            &.active {
              background-color: black !important;
              color: white;

              &:hover,
              &:focus {
                color: white !important;
              }
            }
          }
        }
      }
    }
  }

  .ball-beat > div,
  .ball-grid-beat > div,
  .ball-grid-pulse > div,
  .ball-pulse-sync > div,
  .ball-rotate > div,
  .ball-rotate > div:after,
  .ball-rotate > div:before,
  .ball-scale-multiple > div,
  .ball-scale-ripple > div,
  .ball-spin-fade-loader > div,
  .ball-triangle-path > div,
  .ball-zig-zag > div,
  .cube-transition > div,
  .line-scale-pulse-out-rapid > div,
  .line-scale > div,
  .square-spin > div {
    background: $cidery-primary;
  }

  .cidery.call-outs {
    .call-out {
      background-position: center center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;

      &:nth-child(1n) {
        background: url('/themes/Conns/assets/dist/images/img-landingPage-7thStreetCidery-history.jpg');
      }

      &:nth-child(2n) {
        background: url('/themes/Conns/assets/dist/images/img-landingPage-7thStreetCidery-photoGallery.jpg');
      }

      &:nth-child(3n) {
        background: url('/themes/Conns/assets/dist/images/img-landingPage-7thStreetCidery-contactUs.jpg');
      }
    }
  }

  .location-info {
    .widget {
      h3 span::before {
        color: $cidery-primary;
      }

      a {
        color: $cidery-primary;
        font-size: 16px;
        font-weight: 400;

        &:hover,
        &:focus {
          color: black !important;

          span:before {
            color: $cidery-primary !important;
          }
        }
      }
    }
  }

  .socials {
    position: absolute;
    top: 25%;
    right: -8%;

    a {
      color: $cidery-primary !important;
      font-weight: 400;

      &:hover,
      &:focus {
        color: black !important;
      }
    }
  }

  footer.footer-light {
    border-top: 1px solid $cidery-primary !important;
    background-color: $cidery-light-green !important;

    .footer-content {
      color: $cidery-primary !important;

      .widget {
        span {
          color: $cidery-primary !important;

          a {
            color: $cidery-primary !important;
            font-weight: 400;
          }
        }

        ul.list-icon {
          li {
            span {
              &:before {
                color: $cidery-primary !important;
              }
            }

            strong {
              color: $cidery-primary !important;
            }
          }
        }
      }

      a {
        color: $cidery-primary !important;
        font-weight: 400;
      }
    }
  }

  .background-overlay {
    .parallax-container {
      background-position: center center !important;
    }
  }

  input[type='submit'] {
    background-color: $cidery-green;

    &:hover {
      background-color: $cidery-primary;
    }
  }
}
