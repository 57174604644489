body.wm-vans {
  #side-panel-trigger {
    color: $wmvans-tan !important;
  }

  #side-panel {
    background-color: $wmvans-darkred;

    nav.primary-nav {
      .ul1 {
        .li1 {
          .a1 {
            color: white !important;
          }
        }
      }
    }
  }

  ::selection {
    background: $wmvans-red;
  }

  a {
    color: $wmvans-red;
  }

  .page-menu.page-menu-active {
    nav {
      background-color: $wmvans-darkred !important;

      ul {
        li {
          a {
            color: white;
          }
        }
      }
    }
  }

  .subnav-container {
    background: $wmvans-tan;

    .page-title {
      background: $wmvans-red;
      color: white;
    }

    #menu-responsive-icon {
      background-color: $wmvans-red !important;
      color: white;

      &:before {
        background-color: $wmvans-tan;
      }
    }

    nav {
      ul {
        li {
          &:hover,
          &:active {
            a {
              color: $wmvans-red;

              &:after {
                background-color: $wmvans-red;
              }
            }
          }

          a {
            &.active {
              background-color: $wmvans-darkred !important;
              color: white;
            }
          }
        }
      }
    }
  }

  .ball-beat > div,
  .ball-grid-beat > div,
  .ball-grid-pulse > div,
  .ball-pulse-sync > div,
  .ball-rotate > div,
  .ball-rotate > div:after,
  .ball-rotate > div:before,
  .ball-scale-multiple > div,
  .ball-scale-ripple > div,
  .ball-spin-fade-loader > div,
  .ball-triangle-path > div,
  .ball-zig-zag > div,
  .cube-transition > div,
  .line-scale-pulse-out-rapid > div,
  .line-scale > div,
  .square-spin > div {
    background: $wmvans-red;
  }

  .wm-vans.call-outs {
    .call-out {
      background-position: center center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;

      &:nth-child(1n) {
        background: url('/themes/Conns/assets/dist/images/img-wmVans-storyOfWmVans.jpg');
      }

      &:nth-child(2n) {
        background: url('/themes/Conns/assets/dist/images/img-wmVans-photoGallery.jpg');
      }

      &:nth-child(3n) {
        background: url('/themes/Conns/assets/dist/images/img-wmVans-contactUs.jpg');
      }
    }
  }

  .location-info {
    .widget {
      span {
        color: $wmvans-red;
      }

      a {
        color: $wmvans-red;
        font-size: 16px;
      }
    }
  }

  .socials {
    position: absolute;
    top: 25%;
    right: -8%;

    a {
      color: $wmvans-red !important;

      &:hover {
        color: $wmvans-darkred !important;
      }
    }
  }

  footer.footer-light {
    border-top: 1px solid $wmvans-tan !important;
    background-color: $wmvans-red !important;

    .footer-content {
      color: white !important;

      .widget {
        span {
          color: white !important;

          a {
            color: white !important;
          }
        }

        ul.list-icon {
          li {
            span {
              &:before {
                color: white !important;
              }
            }

            strong {
              color: white !important;
            }
          }
        }
      }

      a {
        color: white !important;
      }
    }
  }

  .background-overlay {
    .parallax-container {
      background-position: center center !important;
    }
  }

  input[type='submit'] {
    background-color: $wmvans-red;

    &:hover {
      background-color: $wmvans-darkred;
    }
  }
}
